import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Label from "../label/Label";
import SingleLineTextInput from "../singleLineTextInput/SingleLineTextInput";
import Button from "../button/Button";

const styles = theme => ({
  addNewTextItem: {
    display: "flex",
    marginBottom: 10
  }
});

function AddNewTextItem({ classes, label, buttonText, onClick }) {
  const [payload, storePayload] = React.useState({});

  return (
    <div>
      {label && <Label title={label} />}
      <div className={classes.addNewTextItem}>
        <SingleLineTextInput
          style={{ marginBottom: 0, marginRight: 10, width: "100%", maxWidth: 500 }}
          value={payload.newItemText}
          onChange={value => storePayload({ ...payload, newItemText: value })}
        />
        <Button
          title={buttonText}
          onClick={() => {
            onClick(payload);
            storePayload({ ...payload, newItemText: "" });
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(AddNewTextItem);
