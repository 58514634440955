import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getFeeCategories as apiGetFeeCategories,
  createFeeCategory as apiCreateFeeCategory,
  deleteFeeCategory as apiDeleteFeeCategory,
  updateFeeCategory as apiUpdateFeeCategory,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import Section from "../../components/section/Section";

import FeeCategoriesTable from "../../tables/feeCategoriesTable/FeeCategoriesTable";
import AddFeeCategoryForm from "../../forms/addFeeCategoryForm/AddFeeCategoryForm";
import EditFeeCategoryForm from "../../forms/editFeeCategoryForm/EditFeeCategoryForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function FeeCategoriesPage({ history, classes, dispatch, context }) {
  const [showAddFeeCategory, toggleShowAddFeeCategory] = React.useState(false);
  const [newFeeCategory, storeNewFeeCategory] = React.useState(false);
  // const [gettingFeeCategories, setGettingFeeCategories] = React.useState(false);
  const [feeCategories, setFeeCategories] = React.useState([]);
  const [creatingFeeCategory, setCreatingFeeCategory] = React.useState(false);
  const [createFeeCategoryError, setCreateFeeCategoryError] = React.useState("");
  const [editFeeCategory, setEditFeeCategory] = React.useState(null);
  const [updatingFeeCategory, setUpdatingFeeCategory] = React.useState(false);
  const [updateFeeCategoryError, setUpdateFeeCategoryError] = React.useState("");
  const [showDeleteFeeCategory, setDeleteFeeCategory] = React.useState(null);
  const [deletingFeeCategory, setDeletingFeeCategory] = React.useState(false);
  const [deleteFeeCategoryError, setDeleteFeeCategoryError] = React.useState("");

  const getFeeCategories = useCallback(async () => {
    if (!context.currentClub || !context.currentClub.clubId || !context.currentPlayer || !context.currentPlayer.id) {
      return false;
    }

    try {
      // setGettingFeeCategories(true);
      const data = await apiGetFeeCategories(context.currentClub.clubId, context.currentPlayer.id);
      if (data && !data.reason) {
        setFeeCategories(data);
      }
      // setGettingFeeCategories(false);
    } catch (e) {
      console.log(e);
      // setGettingFeeCategories(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const createFeeCategory = async (payload) => {
    if (!payload.name) {
      return setCreateFeeCategoryError("Please enter a fee category name");
    }

    setCreateFeeCategoryError("");

    try {
      setCreatingFeeCategory(true);
      await apiCreateFeeCategory(context.currentClub.clubId, {
        name: payload.name,
      });
      setCreatingFeeCategory(false);
      toggleShowAddFeeCategory(false);
      getFeeCategories();
    } catch (e) {
      setCreatingFeeCategory(false);
      setCreateFeeCategoryError(e.message);
    }
  };

  const updateFeeCategory = async (payload) => {
    if (!payload.name) {
      return setUpdateFeeCategoryError("Please enter a fee category name");
    }

    setUpdateFeeCategoryError("");

    try {
      setUpdatingFeeCategory(true);
      await apiUpdateFeeCategory(context.currentClub.clubId, payload.id, payload);
      setEditFeeCategory(null);
      setUpdatingFeeCategory(false);
      getFeeCategories();
    } catch (e) {
      setUpdatingFeeCategory(false);
      setUpdateFeeCategoryError(e.message);
    }
  };

  const deleteFeeCategory = async () => {
    try {
      setDeletingFeeCategory(true);
      await apiDeleteFeeCategory(context.currentClub.clubId, showDeleteFeeCategory.id);
      setDeletingFeeCategory(false);
      setDeleteFeeCategory(null);
      getFeeCategories();
    } catch (e) {
      setDeletingFeeCategory(false);
      setDeleteFeeCategoryError(e.message);
    }
  };

  useEffect(() => {
    getFeeCategories();
  }, [getFeeCategories]);

  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true} title="Fee levels">
      <Section>
        <FeeCategoriesTable
          feeCategories={feeCategories}
          addButtonText="Add fee category"
          onAddButtonClick={() => {
            toggleShowAddFeeCategory(true);
            storeNewFeeCategory({});
          }}
          onEditFeeCategory={(feeCategory) => setEditFeeCategory(feeCategory)}
          onDeleteFeeCategory={(feeCategory) => setDeleteFeeCategory(feeCategory)}
        />
      </Section>

      {showAddFeeCategory && (
        <DialogModal submitText="Add" onSubmit={() => createFeeCategory(newFeeCategory)} onClose={() => toggleShowAddFeeCategory(false)} error submitting={creatingFeeCategory}>
          <AddFeeCategoryForm payload={newFeeCategory} storePayload={(payload) => storeNewFeeCategory(payload)} errorMessage={createFeeCategoryError} />
        </DialogModal>
      )}

      {editFeeCategory && (
        <DialogModal title="Edit fee category" submitText="Update" onSubmit={() => updateFeeCategory(editFeeCategory)} onClose={() => setEditFeeCategory(null)} error submitting={updatingFeeCategory}>
          <EditFeeCategoryForm payload={editFeeCategory} storePayload={(payload) => setEditFeeCategory(payload)} errorMessage={updateFeeCategoryError} />
        </DialogModal>
      )}

      {showDeleteFeeCategory && (
        <YesNoDialogModal
          title="Are you sure you want to delete this fee category?"
          onClose={() => setDeleteFeeCategory(false)}
          onSubmit={() => deleteFeeCategory()}
          submitting={deletingFeeCategory}
          error={deleteFeeCategoryError}
        />
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(FeeCategoriesPage));
