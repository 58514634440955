import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  table: {
    width: "auto",
  },
  tableEventTypeCell: {
    width: "100px",
  },
  tablePriceCell: {
    width: "200px",
  },
});

function EventFeesTable({ classes, eventTypes, feeCategories, eventFees, addButtonText, onAddButtonClick, hideSearch, onEditEventFee }) {
  const getEventFee = (eventTypeId, feeCategoryId) => {
    return eventFees.find((eventFee) => {
      return eventFee.eventType && eventFee.eventType.id === eventTypeId && eventFee.feeCategory && eventFee.feeCategory.id === feeCategoryId;
    });
  };

  const renderTableHeadings = () => {
    return feeCategories.map((feeCategory) => {
      return <TableCell className={classes.tablePriceCell}>{feeCategory.name}</TableCell>;
    });
  };

  const renderTableRows = () => {
    let tableRows = [];
    eventTypes.forEach((eventType) => {
      let tableRow = [];
      tableRow.push(<TableCell className={classes.tableCell}>{eventType.name}</TableCell>);
      feeCategories.forEach((feeCategory) => {
        const existingEventFee = getEventFee(eventType.id, feeCategory.id);
        tableRow.push(
          <TableCell className={classes.tableCell}>
            <SingleLineTextInput
              value={existingEventFee && existingEventFee.defaultFee}
              onChange={(value) => onEditEventFee(eventType.id, feeCategory.id, value.replace(/[^0-9,.]/g, ""))}
              startAdornment="£"
            />
          </TableCell>
        );
      });

      tableRows.push(<TableRow>{tableRow}</TableRow>);
    });

    return tableRows;
  };

  return (
    <div className={classes.section}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableEventTypeCell}></TableCell>
            {renderTableHeadings()}
          </TableRow>
        </TableHead>
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
    </div>
  );
}

export default withStyles(styles)(EventFeesTable);
