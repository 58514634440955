import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import { getEventTypes as apiGetEventTypes, createEventType as apiCreateEventType, deleteEventType as apiDeleteEventType, updateEventType as apiUpdateEventType } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import Section from "../../components/section/Section";

import EventTypesTable from "../../tables/eventTypesTable/EventTypesTable";
import AddEventTypeForm from "../../forms/addEventTypeForm/AddEventTypeForm";
import EditEventTypeForm from "../../forms/editEventTypeForm/EditEventTypeForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function EventTypesPage({ history, classes, dispatch, context }) {
  const [showAddEventType, toggleShowAddEventType] = React.useState(false);
  const [newEventType, storeNewEventType] = React.useState(false);
  const [eventTypes, setEventTypes] = React.useState([]);
  // const [gettingEventTypes, setGettingEventTypes] = React.useState(false);
  const [creatingEventType, setCreatingEventType] = React.useState(false);
  const [createEventTypeError, setCreateEventTypeError] = React.useState("");
  const [editEventType, setEditEventType] = React.useState(null);
  const [updatingEventType, setUpdatingEventType] = React.useState(false);
  const [updateEventTypeError, setUpdateEventTypeError] = React.useState("");
  const [showDeleteEventType, setDeleteEventType] = React.useState(null);
  const [deletingEventType, setDeletingEventType] = React.useState(false);
  const [deleteEventTypeError, setDeleteEventTypeError] = React.useState("");

  const getEventTypes = useCallback(async () => {
    if (!context.currentClub || !context.currentClub.clubId || !context.currentPlayer || !context.currentPlayer.id) {
      return false;
    }

    try {
      // setGettingEventTypes(true);
      const data = await apiGetEventTypes(context.currentClub.clubId);
      if (data && !data.reason) {
        setEventTypes(data);
      }
      // setGettingEventTypes(false);
    } catch (e) {
      console.log(e);
      // setGettingEventTypes(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const createEventType = async (payload) => {
    if (!payload.name) {
      return setCreateEventTypeError("Please enter an event type name");
    }

    setCreateEventTypeError("");

    try {
      setCreatingEventType(true);
      await apiCreateEventType(context.currentClub.clubId, {
        name: payload.name,
      });
      setCreatingEventType(false);
      toggleShowAddEventType(false);
      getEventTypes();
    } catch (e) {
      setCreatingEventType(false);
      setCreateEventTypeError(e.message);
    }
  };

  const updateEventType = async (payload) => {
    if (!payload.name) {
      return setUpdateEventTypeError("Please enter an event type name");
    }

    setUpdateEventTypeError("");

    try {
      setUpdatingEventType(true);
      await apiUpdateEventType(context.currentClub.clubId, payload.id, payload);
      setEditEventType(null);
      setUpdatingEventType(false);
      getEventTypes();
    } catch (e) {
      setUpdatingEventType(false);
      setUpdateEventTypeError(e.message);
    }
  };

  const deleteEventType = async () => {
    try {
      setDeletingEventType(true);
      await apiDeleteEventType(context.currentClub.clubId, showDeleteEventType.id);
      setDeletingEventType(false);
      setDeleteEventType(null);
      getEventTypes();
    } catch (e) {
      setDeletingEventType(false);
      setDeleteEventTypeError(e.message);
    }
  };

  useEffect(() => {
    getEventTypes();
  }, [getEventTypes]);

  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true} title="Event types">
      <Section>
        <EventTypesTable
          eventTypes={eventTypes}
          addButtonText="Add event type"
          onAddButtonClick={() => {
            toggleShowAddEventType(true);
            storeNewEventType({});
          }}
          onEditEventType={(eventType) => setEditEventType(eventType)}
          onDeleteEventType={(eventType) => setDeleteEventType(eventType)}
        />
      </Section>

      {showAddEventType && (
        <DialogModal submitText="Add" onSubmit={() => createEventType(newEventType)} onClose={() => toggleShowAddEventType(false)} error submitting={creatingEventType}>
          <AddEventTypeForm payload={newEventType} storePayload={(payload) => storeNewEventType(payload)} errorMessage={createEventTypeError} />
        </DialogModal>
      )}

      {editEventType && (
        <DialogModal title="Edit event type" submitText="Update" onSubmit={() => updateEventType(editEventType)} onClose={() => setEditEventType(null)} error submitting={updatingEventType}>
          <EditEventTypeForm payload={editEventType} storePayload={(payload) => setEditEventType(payload)} errorMessage={updateEventTypeError} />
        </DialogModal>
      )}

      {showDeleteEventType && (
        <YesNoDialogModal
          title="Are you sure you want to delete this event type?"
          onClose={() => setDeleteEventType(false)}
          onSubmit={() => deleteEventType()}
          submitting={deletingEventType}
          error={deleteEventTypeError}
        />
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(EventTypesPage));
