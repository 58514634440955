import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";

import Label from "../label/Label";

const styles = theme => ({
  checkbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "10px 0"
  },
  checkboxItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  checkboxItemSelect: {
    height: 30,
    width: 30,
    border: "2px solid #3cc77b",
    marginRight: 5,
    cursor: "pointer"
  }
});

function Checkbox({ classes, label, value, onClick }) {
  return (
    <div className={classes.checkbox}>
      <div onClick={() => onClick()} className={classes.checkboxItem}>
        <div className={classes.checkboxItemSelect}>{value && <CheckIcon />}</div>
      </div>
      {label && <Label title={label} />}
    </div>
  );
}

export default withStyles(styles)(Checkbox);
