import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  authLinks: {
    marginTop: 20,
  },
  authLink: {
    margin: "10px 0",
  },
});

const isInvalidEmailAddress = (emailAddress) => !emailAddress;
const isInvalidPassword = (password) => !password;

function LoginForm({ classes, onSubmit, errorMessage, submitting }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState({});

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Login" />
      </div>
      <SingleLineTextInput label="USERNAME" onChange={(value) => storePayload({ ...payload, emailAddress: value })} showInvalidInput={submitAttempted && isInvalidEmailAddress(payload.emailAddress)} />
      <SingleLineTextInput
        label="PASSWORD"
        onChange={(value) => storePayload({ ...payload, password: value })}
        showInvalidInput={submitAttempted && isInvalidPassword(payload.password)}
        type="password"
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Button
        title="Login"
        onClick={() => {
          setSubmitAttempted(true);
          onSubmit(payload);
        }}
        submitting={submitting}
      />
      <div className={classes.authLinks}>
        <Link to="/forgot-password">
          <div className={classes.authLink}>Forgot your password?</div>
        </Link>
        <Link to="/register">
          <div className={classes.authLink}>Create an account</div>
        </Link>
      </div>
    </div>
  );
}

export default withStyles(styles)(LoginForm);
