import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = theme => ({
  section: {
    width: "100%",
    backgroundColor: "#fff"
  }
});

const headings = [
  {
    label: "NAME",
    key: "name"
  }
];

function EventTypesTable({ classes, eventTypes, addButtonText, onAddButtonClick, hideSearch, onEditEventType, onDeleteEventType }) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={eventTypes}
        onAddButtonClick={onAddButtonClick ? payload => onAddButtonClick(payload) : null}
        rowActions={[
          { type: "edit", onClick: onEditEventType ? member => onEditEventType(member) : null },
          { type: "delete", onClick: onDeleteEventType ? member => onDeleteEventType(member) : null }
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(EventTypesTable);
