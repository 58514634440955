import React from "react";

import { withState } from "../../../services/state";
import { resetPassword as apiResetPassword } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

import ResetPasswordForm from "../../forms/resetPasswordForm/ResetPasswordForm";

const isInvalidEmail = (email) => !email;
const isInvalidPassword = (password) => !password || password.length < 6;

const ResetPasswordPage = ({ history, match, dispatch }) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [resettingPassword, setResettingPassword] = React.useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = React.useState("");
  const [resetPasswordError, setResetPasswordError] = React.useState("");

  const resetPassword = async (payload, callback) => {
    try {
      setResetPasswordError(null);

      if (isInvalidEmail(payload.emailAddress)) {
        setResetPasswordError("Please enter a valid email address");
        return false;
      }

      if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
        setResetPasswordError("Password does not match requirements");
        return false;
      }

      if (isInvalidPassword(payload.password)) {
        setResetPasswordError("Please enter a valid password");
        return false;
      }

      setResettingPassword(true);
      const resetPasswordData = await apiResetPassword({ code: match.params.code, ...payload });
      console.log(resetPasswordData);
      if (resetPasswordData.success) {
        setResetPasswordMessage("Your password has successfully been set");
        callback();
      } else if (resetPasswordData.errors) {
        setResetPasswordError(resetPasswordData.errors[0].reason);
      } else {
        setResetPasswordError("An error occurred trying to request password reset");
      }
      setResettingPassword(false);
    } catch (e) {
      console.log(e);
      setResettingPassword(false);
      setResetPasswordError(e.message);
    }
  };

  console.log(resetPasswordError);

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={currentStep}
        onGoBack={() => setCurrentStep(currentStep - 1)}
        steps={[
          <ResetPasswordForm
            onSubmit={(payload, callback) => {
              resetPassword(payload, callback);
            }}
            submitting={resettingPassword}
            error={resetPasswordError}
            message={resetPasswordMessage}
            submitText="Save password"
          />,
        ]}
      />
    </PageWrapper>
  );
};

export default withState(ResetPasswordPage);
