import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../../components/formHeader/FormHeader";
import FormDescription from "../../../components/formDescription/FormDescription";
import Button from "../../../components/button/Button";
import AddNewTextItem from "../../../components/addNewTextItem/AddNewTextItem";
import ErrorMessage from "../../../components/errorMessage/ErrorMessage";

import EventTypesTable from "../../../tables/eventTypesTable/EventTypesTable";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  textSection: {
    marginBottom: 20,
  },
});

function EditEventTypesForm({
  classes,
  eventTypes,
  onSubmit,
  errorMessage,
  submitting,
  onCreateEventType,
  onEditEventType,
  onDeleteEventType,
}) {
  const renderEventTypes = () => {
    return (
      <EventTypesTable
        hideSearch={true}
        eventTypes={eventTypes}
        addButtonText="Add event type"
        onEditEventType={(eventType) => onEditEventType(eventType)}
        onDeleteEventType={(eventType) => onDeleteEventType(eventType)}
      />
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Event Types" />
        <FormDescription>
          <div className={classes.textSection}>
            <div>
              Here you will set up the different fixtures and events you will
              collect money for, for example:
            </div>
            <div>
              <div>Home matches</div>
              <div>Training sessions</div>
              <div>Winter nets</div>
            </div>
          </div>
          <div className={classes.textSection}>
            <div>You can even setup separate events, for example:</div>
            <div>
              <div>End of season dinner</div>
              <div>Annual subs</div>
            </div>
          </div>
        </FormDescription>
      </div>
      <AddNewTextItem
        label="EVENT NAME*"
        buttonText="Add event"
        onClick={(payload) => onCreateEventType(payload)}
      />
      <ErrorMessage message={errorMessage} />
      <div style={{ margin: "30px 0" }}>{renderEventTypes()}</div>
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            onSubmit(eventTypes);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(EditEventTypesForm);
