import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

const headings = [
  {
    label: "Team name",
    key: "teamName",
  },
  {
    label: "Paid",
    key: "paid",
    type: "currency",
  },
  {
    label: "Event type",
    key: "eventType",
  },
  {
    label: "Total",
    key: "total",
    type: "currency",
  },
  {
    label: "Deficit",
    key: "deficit",
    type: "currency",
  },
];

function ClubReportTable({
  classes,
  summaryReportData,
  addButtonText,
  onAddButtonClick,
  hideSearch,
  onEditFeeCategory,
  onDeleteFeeCategory,
  additionalButtons,
  showDateRangeFilter,
  dateRangeFilterStart,
  onDateRangeFilterStartChange,
  dateRangeFilterEnd,
  onDateRangeFilterEndChange,
  filterSelects,
  showDownloadButton,
  downloadFilename,
}) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={summaryReportData}
        onAddButtonClick={onAddButtonClick ? (payload) => onAddButtonClick(payload) : null}
        rowActions={[
          { type: "edit", onClick: onEditFeeCategory ? (member) => onEditFeeCategory(member) : null },
          { type: "delete", onClick: onDeleteFeeCategory ? (member) => onDeleteFeeCategory(member) : null },
        ]}
        additionalButtons={additionalButtons}
        showDateRangeFilter={showDateRangeFilter}
        dateRangeFilterStart={dateRangeFilterStart}
        onDateRangeFilterStartChange={(value) => onDateRangeFilterStartChange(value)}
        dateRangeFilterEnd={dateRangeFilterEnd}
        onDateRangeFilterEndChange={(value) => onDateRangeFilterEndChange(value)}
        filterSelects={filterSelects}
        showDownloadButton={showDownloadButton}
        downloadFilename={downloadFilename}
      />
    </div>
  );
}

export default withStyles(styles)(ClubReportTable);
