import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import FormDescription from "../../components/formDescription/FormDescription";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

import EventFeesTable from "../../tables/eventFeesTable/EventFeesTable";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  eventFeeTable: {
    marginBottom: 20,
  },
});

function EditEventFeesForm({
  classes,
  onSubmit,
  submitting,
  eventFees,
  eventTypes,
  feeCategories,
  onEditEventFee,
  errorMessage,
}) {
  const renderEventFees = () => {
    return (
      <EventFeesTable
        hideSearch={true}
        eventTypes={eventTypes}
        feeCategories={feeCategories}
        eventFees={eventFees}
        onEditEventFee={(eventTypeId, feeCategoryId, value) =>
          onEditEventFee(eventTypeId, feeCategoryId, value)
        }
      />
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Set your fees" />
        <FormDescription>
          <div>Here you set the fee amount for each type of player.</div>
          <div>
            Slate takes a small commission on each payment, to find out what the
            fees are, please click{" "}
            <a href="https://slateapp.co.uk/faqs/">here</a>.
          </div>
          <div>
            If you make a mistake, don’t worry, you will be able to go back an
            edit the fees.
          </div>
        </FormDescription>
      </div>

      <div style={{ margin: "30px 0" }}>{renderEventFees()}</div>
      <ErrorMessage message={errorMessage} />

      <Button
        title="Submit"
        onClick={() => {
          onSubmit();
        }}
        submitting={submitting}
      />
    </div>
  );
}

export default withStyles(styles)(EditEventFeesForm);
