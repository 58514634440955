import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getProfile as apiGetProfile,
  // createPlayer as apiCreatePlayer,
  loginToClub as apiLoginToClub,
  logoutOfClub as apiLogoutOfClub,
  resendVerificationEmail as apiResendVerificationEmail,
} from "../../../services/api";

import PageHeader from "../pageHeader/PageHeader";
import PageContent from "../pageContent/PageContent";
import DialogModal from "../dialogModal/DialogModal";
import Button from "../button/Button";

// import SelectPlayerForm from "../../forms/selectPlayerForm/SelectPlayerForm";
import SelectClubForm from "../..//forms/selectClubForm/SelectClubForm";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
});

function PageWrapper({
  history,
  classes,
  context,
  dispatch,
  children,
  showHeader,
  showSidebar,
  title,
}) {
  const [showMinimizedSidebar, setShowMinimizedSidebar] = React.useState(false);
  // const [creatingPlayer, setCreatingPlayer] = React.useState(false);
  // const [createPlayerError, setCreatePlayerError] = React.useState("");
  const [
    resendingVerificationEmail,
    setResendingVerificationEmail,
  ] = React.useState(false);
  // const [showCreatePlayer, setShowCreatePlayer] = React.useState(false);

  // const showSelectPlayer = context.showSelectPlayer;
  const showSelectClub = context.showSelectClub;

  // const createPlayer = async (payload) => {
  //   try {
  //     // setCreatingPlayer(true);
  //     await apiCreatePlayer(payload);
  //     await getProfile();

  //     closeSelectPlayer();
  //     // setCreatingPlayer(false);
  //   } catch (e) {
  //     console.log(e);
  //     // setCreatingPlayer(false);
  //     // setCreatePlayerError(e.message);
  //   }
  // };

  const logout = () => {
    dispatch({
      type: "clearToken",
    });
    dispatch({
      type: "clearSessionVariables",
    });
    history.push("/");
  };

  const getProfile = async () => {
    try {
      const data = await apiGetProfile();

      if (data) {
        dispatch({
          type: "storeProfile",
          profile: data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      setResendingVerificationEmail(true);
      await apiResendVerificationEmail(context.organizationData.guid);
      setResendingVerificationEmail(false);
    } catch (e) {
      console.log(e);
      setResendingVerificationEmail(false);
    }
  };

  // const selectPlayer = async (payload) => {
  //   dispatch({
  //     type: "storeCurrentPlayer",
  //     currentPlayer: payload,
  //   });
  //   closeSelectPlayer();
  // };

  const loginToClub = async (payload) => {
    const loginToClubData = await apiLoginToClub(
      payload.club.clubId,
      payload.player.id
    );

    if (loginToClubData && loginToClubData.accessToken) {
      dispatch({
        type: "storeSessionVariables",
        currentClub: payload.club,
        currentPlayer: payload.player,
        tokens: {
          accessToken: loginToClubData.accessToken,
          refreshToken: loginToClubData.refreshToken,
        },
      });
      closeSelectClub();
    }

    if (!payload.club.setupComplete) {
      history.push("/create-club");
    } else {
      history.push("/reports");
    }
  };

  const clearClub = async () => {
    if (context.currentClub && context.currentClub.clubId) {
      const logoutOfClubData = await apiLogoutOfClub(
        context.currentClub.clubId
      );
      if (logoutOfClubData && logoutOfClubData.accessToken) {
        dispatch({
          type: "storeSessionVariables",
          currentClub: null,
          currentPlayer: context.currentPlayer,
          tokens: {
            accessToken: logoutOfClubData.accessToken,
            refreshToken: logoutOfClubData.refreshToken,
          },
        });
        closeSelectClub();
      }
    }
    history.push("/create-club");
  };

  // const closeSelectPlayer = () => {
  //   dispatch({
  //     type: "closeSelectPlayer",
  //   });
  // };

  const closeSelectClub = () => {
    dispatch({
      type: "closeSelectClub",
    });
  };

  if (!context.loaded) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      {showHeader && (
        <PageHeader
          setShowMinimizedSidebar={() =>
            setShowMinimizedSidebar(!showMinimizedSidebar)
          }
        />
      )}
      <PageContent
        history={history}
        showHeader={showHeader}
        showSidebar={showSidebar}
        title={title}
        showMinimizedSidebar={showMinimizedSidebar}
      >
        {children}
      </PageContent>

      {/* {showSelectPlayer && (
        <DialogModal submitText={showCreatePlayer && "Create new player"} onSubmit={(payload) => showCreatePlayer && createPlayer(payload)} onClose={() => closeSelectPlayer()} >
          <SelectPlayerForm players={(context.profile && context.profile.players) || []} onSelectPlayer={(payload) => selectPlayer(payload)} onClose={() => closeSelectPlayer()} setShowCreatePlayer={(value) => setShowCreatePlayer(value)} showCreatePlayer={showCreatePlayer} />
        </DialogModal>
      )} */}

      {context.currentPlayer &&
        context.currentPlayer.memberships &&
        context.currentClub &&
        showSelectClub && (
          <DialogModal
            cancelText={!context.currentClub.clubId ? "Logout" : null}
            submitText="Create new club"
            onSubmit={() => {
              closeSelectClub();
              clearClub();
            }}
            onClose={() =>
              !context.currentClub.clubId ? logout() : closeSelectClub()
            }
          >
            <SelectClubForm
              players={context.profile && context.profile.players}
              organizationId={context.organizationData.guid}
              onLoginToClub={(payload) => loginToClub(payload)}
              onClose={() => closeSelectClub()}
            />
          </DialogModal>
        )}

      {context.profile &&
        context.profile.id &&
        !context.profile.isEmailActivated &&
        !window.location.pathname.startsWith("/verify-account") && (
          <DialogModal title="Please confirm your email address">
            <div style={{ textAlign: "center" }}>
              <div style={{ marginBottom: "20px" }}>
                Please check your mailbox and junk folder for your verification
                email from{" "}
                <a href="mailto:help@slateapp.co.uk">help@slateapp.co.uk</a>.
              </div>
              <div>
                <Button
                  style={{ marginBottom: 20 }}
                  title="I have verified my email address"
                  onClick={() => {
                    getProfile();
                  }}
                />
              </div>
              <div>
                <Button
                  style={{
                    backgroundColor: "rgb(243, 243, 243)",
                    color: "#222",
                    marginBottom: 10,
                  }}
                  title="Resend verification email"
                  onClick={() => resendVerificationEmail()}
                  submitting={resendingVerificationEmail}
                />
              </div>
            </div>
          </DialogModal>
        )}
    </div>
  );
}

export default withStyles(styles)(withState(PageWrapper));
