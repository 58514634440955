import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getProfile as apiGetProfile,
  register as apiRegister,
  createPlayer as apiCreatePlayer,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";
import DialogModal from "../../components/dialogModal/DialogModal";

import RegisterForm from "../../forms/registerForm/RegisterForm";

const styles = (theme) => ({
  paragraph: {
    marginBottom: 20,
  },
});

const RegisterPage = ({ classes, history, dispatch, context }) => {
  // const [currentStep, setCurrentStep] = React.useState(0);
  const [registering, setRegistering] = React.useState(false);
  const [registerError, setRegisterError] = React.useState("");
  const [showTreasurerIntro, setShowTreasurerIntro] = React.useState(true);

  const register = async (payload) => {
    if (
      !payload.firstName ||
      !payload.lastName ||
      !payload.emailAddress ||
      !payload.password
    ) {
      setRegisterError("Please complete all fields");
      return false;
    }

    if (payload.emailAddress !== payload.confirmEmailAddress) {
      setRegisterError("Email addresses do not match");
      return false;
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
      setRegisterError("Password does not match requirements");
      return false;
    }

    if (payload.password !== payload.confirmPassword) {
      setRegisterError("Passwords do not match");
      return false;
    }

    if (!payload.agreeToTerms) {
      setRegisterError("Please agree to the terms and conditions");
      return false;
    }

    setRegisterError("");

    try {
      setRegistering(true);
      const registerData = await apiRegister({
        ...payload,
        organizationId: context.organizationData.guid,
      });
      if (registerData.success) {
        dispatch({
          type: "storeToken",
          tokens: {
            accessToken: registerData.accessToken,
            refreshToken: registerData.refreshToken,
          },
        });
        await apiCreatePlayer(payload);
        await getProfile();
        dispatch({
          type: "siteLoaded",
          loggedIn: true,
          loaded: false,
        });
      } else if (registerData.errors) {
        setRegisterError(registerData.errors[0].reason);
      } else {
        setRegisterError("An error occurred trying to register");
      }
      setRegistering(false);
    } catch (e) {
      setRegistering(false);
      setRegisterError(e.message);
    }
  };

  const getProfile = async () => {
    try {
      const data = await apiGetProfile();

      if (data) {
        if (data && data.players && data.players.length > 0) {
          dispatch({
            type: "storeSessionVariables",
            currentPlayer: data.players[0],
          });
        }

        dispatch({
          type: "storeProfile",
          profile: data,
        });

        history.push("/create-club");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        // currentStep={currentStep}
        currentStep={0}
        onGoBack={() => history.push("/login")}
        steps={[
          <RegisterForm
            onSubmit={(payload) => {
              register(payload);
            }}
            registering={registering}
            registerError={registerError}
            submitText="Register"
          />,
        ]}
      />

      {showTreasurerIntro && (
        <DialogModal
          title="Hello there Treasurer!"
          onSubmit={() => setShowTreasurerIntro(false)}
          submitText="Continue"
        >
          <div>
            <div className={classes.paragraph}>
              Think Slate sounds like the ideal solution for your club's money
              collecting woes? Great! You're almost there, but before continuing
              you'll need to have your club's bank details handy so that we can
              set you up to receive your members' payments. As Club Treasurer,
              you personally will be responsible for setting up the team(s)
              within your club, appointing team managers, and setting fee levels
              and event types.
            </div>
            <div className={classes.paragraph}>
              If you're not sure you're the right person for this job, click{" "}
              <a href="mailto:?subject=Slate%20-%20the%20match%20fee%20app&body=Slate%20allows%20you%20to%20take%20complete%20control%20over%20your%20clubs'%20subs%20and%20match%20fee%20payments.%20Treasurers%20have%20the%20ability%20to%20manage%20and%20invoice%20players%20for%20their%20payments%20and%20in%20turn,%20players%20can%20then%20pay%20their%20fees%20via%20the%20app.%20%0D%0A%0D%0ASee%20https://slateapp.co.uk">
                here
              </a>{" "}
              to email the person that is, and let them know why Slate is the
              right choice for your club.
            </div>
            <div className={classes.paragraph}>
              Finally we recommend you watch this{" "}
              <a href="https://www.youtube.com/watch?v=sZuLHPh6Eig">
                Treasurers Tips
              </a>{" "}
              video first to help you with your club set up. If you're happy
              with all the above, continue
            </div>
          </div>
        </DialogModal>
      )}
    </PageWrapper>
  );
};

export default withStyles(styles)(withState(RegisterPage));
