import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

import CaptainsTable from "../../tables/captainsTable/CaptainsTable";

const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditTeamForm({ classes, payload, storePayload, onSubmit, errorMessage, submitting, addingCaptain, members, captains, onAddCaptain, onDeleteCaptain }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Edit team" />
      </div>
      <SingleLineTextInput label="NAME" value={payload.name} onChange={(value) => storePayload({ ...payload, name: value })} showInvalidInput={submitAttempted && isInvalidName(payload.name)} />
      {/* <DropdownSelect
        label="CAPTAIN/MANAGER"
        items={members.map(member => {
          return { value: member.playerId, label: `${member.firstName} ${member.lastName}` };
        })}
        onSelect={memberId => storePayload({ ...payload, captainId: memberId })}
      /> */}
      <div style={{ marginTop: 20, backgroundColor: "#f5f4f4", padding: 10 }}>
        <div>Captains</div>
        <CaptainsTable hideSearch={true} captains={captains} onDeleteCaptain={(captain) => onDeleteCaptain(captain)} />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div style={{ marginBottom: 20 }}>
            <DropdownSelect
              label="ADD A NEW CAPTAIN"
              items={members
                .filter((member) => captains.map((captain) => captain.player.guid).indexOf(member.playerId) === -1)
                .map((member) => {
                  return { value: member.playerId, label: `${member.firstName} ${member.lastName}` };
                })}
              onSelect={(memberId) => storePayload({ ...payload, newCaptainId: memberId })}
            />
          </div>
          <Button
            title="Add"
            onClick={() => {
              setSubmitAttempted(true);
              onAddCaptain(payload.newCaptainId);
            }}
            submitting={addingCaptain}
          />
        </div>
      </div>
      <ErrorMessage message={errorMessage} />
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            setSubmitAttempted(true);
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(EditTeamForm);
