import React from "react";
import { withStyles } from "@material-ui/core/styles";

import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

// const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditEventTypeForm({ classes, payload, storePayload, onSubmit, errorMessage, submitting }) {
  // const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <SingleLineTextInput
        label="NAME"
        value={payload.name}
        onChange={(value) => storePayload({ ...payload, name: value })}
        // showInvalidInput={submitAttempted && isInvalidName(payload.name)}
      />
      <ErrorMessage message={errorMessage} />
    </div>
  );
}

export default withStyles(styles)(EditEventTypeForm);
