import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../../components/formHeader/FormHeader";
import FormDescription from "../../../components/formDescription/FormDescription";
import Button from "../../../components/button/Button";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  textSection: {
    marginBottom: 20,
  },
});

function SetupGoCardlessForm({
  classes,
  goCardlessUrl,
  onSubmit,
  submitting,
  onCreateEventType,
}) {
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Setup Your Club’s Gocardless Account" />
        <FormDescription>
          <div className={classes.textSection}>
            <div style={{ marginBottom: 10 }}>
              In order to receive payments from players, you need to sign up
              with our payment partner GoCardless. Here are some instructions on
              how to setup your club’s Gocardless account:
            </div>
            <div style={{ marginBottom: 10 }}>
              1. Email Address: use the same email address for GoCardless as you
              signed up as Treasurer for Slate.
            </div>
            <div style={{ marginBottom: 10 }}>
              2. Select the first GoCardless package 'Standard' for payment.
            </div>
            <div style={{ marginBottom: 8 }}>
              3. For Clubs: - Select Charity/non profit as your "Business Type”
            </div>
            <div style={{ marginBottom: 8, marginLeft: 10 }}>
              - Select Unregistered sports club or society as the "Charity Type”
            </div>
            <div style={{ marginBottom: 8, marginLeft: 10 }}>
              - Then regardless of your club’s status, GoCardless will ask you
              to enter "Charity Details”.
            </div>
            <div style={{ marginBottom: 8, marginLeft: 10 }}>
              - Enter sports club name in "Official Charity Name" and enter club
              address in "Charity Details”
            </div>
            <div style={{ marginBottom: 8, marginLeft: 10 }}>
              - In "Your Details" section, enter your personal details and
              address Bank account details must be the club’s details and the
              club’s name entered in the "Your name on customer statements"
              field
            </div>
            <div style={{ marginBottom: 10, marginLeft: 10 }}>
              - For Individuals (e.g. weekly five aside): Select Individual/Sole
              Trader
            </div>
            <div>
              4. Verification: You need to send 1p by online banking to verify
              your account. It may take up to 2 days, so check back in 2 days if
              you haven't received a confirmation email from GoCardless,
              sometimes they ask for more information.”
            </div>
          </div>
        </FormDescription>
      </div>
      <a href={goCardlessUrl}>
        <Button title="Setup GoCardless" />
      </a>
    </div>
  );
}

export default withStyles(styles)(SetupGoCardlessForm);
