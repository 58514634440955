import React from "react";
import { withStyles } from "@material-ui/core/styles";

import PageWrapper from "../../components/pageWrapper/PageWrapper";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function SplashPage({ history, classes }) {
  return <PageWrapper history={history} showHeader={true}></PageWrapper>;
}

export default withStyles(styles)(SplashPage);
