import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
    padding: 20,
    marginBottom: "20px"
  },
  title: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "21px",
    marginBottom: "20px"
  }
});

function Section({ classes, title, children }) {
  return (
    <div className={classes.section}>
      {title && <div className={classes.title}>{title}</div>}
      {children}
    </div>
  );
}

export default withStyles(styles)(Section);
