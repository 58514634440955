import React, { useEffect, useCallback } from "react";
import qs from "qs";

import { withState } from "../../../services/state";
import { authorizeGoCardlessMerchant as apiAuthorizeGoCardlessMerchant } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

const AuthorizeGoCardless = ({ history, location }) => {
  const authorizeGoCardless = useCallback(async () => {
    console.log(history, location);
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      await apiAuthorizeGoCardlessMerchant(
        queryParams.code,
        queryParams.state,
        queryParams.error,
        queryParams.error_description
      );
    } catch (e) {
      console.log(e);
    }
  }, [history, location]);

  useEffect(() => {
    authorizeGoCardless();
  }, [authorizeGoCardless]);

  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true}>
      <AuthenticationFlow
        currentStep={0}
        steps={[
          <div>
            <div style={{ marginBottom: 20 }}>
              Your Slate account is now setup and ready to use!
            </div>
            <div style={{ marginBottom: 20 }}>
              On the left of the screen, is the menu for you club’s Slate
              account. If you wish to use Slate as your membership database,
              please click on “player information” and here you can request
              information from your players as well as entering your club’s
              privacy policy.
            </div>
            <div style={{ marginBottom: 20 }}>
              If you wish to access your club’s Gocardless account, please click
              on this link{" "}
              <a href="https://manage.gocardless.com/sign-in">
                https://manage.gocardless.com/sign-in
              </a>
              .
            </div>
            <div style={{ marginBottom: 20 }}>
              If you need any assistance, please contact us at{" "}
              <a href="mailto:help@slateapp.co.uk">help@slateapp.co.uk</a>.
            </div>
          </div>,
        ]}
      />
    </PageWrapper>
  );
};

export default withState(AuthorizeGoCardless);
