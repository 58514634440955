import React, { Component, createContext, useReducer } from "react";

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const withState = (ComponentToWrap) => {
  return class StateComponent extends Component {
    render() {
      return (
        <StateContext.Consumer>
          {(context) => (
            <ComponentToWrap
              {...this.props}
              context={context[0]}
              dispatch={context[1]}
            />
          )}
        </StateContext.Consumer>
      );
    }
  };
};

export const initialState = {
  loggedIn: false,
  loaded: false,
  players: [],
  currentPlayer: {},
  clubs: [],
  currentClub: {},
};

export const reducer = (context, action) => {
  switch (action.type) {
    case "siteLoaded": {
      if (action.tokens) {
        localStorage.setItem("slate-tokens", JSON.stringify(action.tokens));
      }
      return {
        ...context,
        tokens: action.tokens || context.tokens,
        currentPlayer: action.currentPlayer || {},
        currentClub: action.currentClub || {},
        loaded: action.loaded,
        loggedIn: action.loggedIn || false,
        showSelectClub: action.showSelectClub || false,
        profile: action.profile || {},
        organizationData: action.organizationData || {},
      };
    }

    case "loggedInAsAdmin": {
      if (action.tokens) {
        localStorage.setItem(
          "slate-player-web-app-admin-tokens",
          JSON.stringify(action.tokens)
        );
      }
      return {
        ...context,
        adminTokens: action.tokens,
        loaded: action.loaded,
        loggedInAsAdmin: action.loggedInAsAdmin || false,
      };
    }

    case "storeSessionVariables": {
      if (action.tokens) {
        localStorage.setItem("slate-tokens", JSON.stringify(action.tokens));
      }
      return {
        ...context,
        tokens: action.tokens || context.tokens,
        currentPlayer: action.currentPlayer || {},
        currentClub: action.currentClub || {},
      };
    }

    case "siteLoadedWithNoClub": {
      return { ...context, loaded: true };
    }

    case "storeToken": {
      localStorage.setItem("slate-tokens", JSON.stringify(action.tokens));
      return context;
    }

    case "clearToken": {
      localStorage.removeItem("slate-tokens");
      return { ...context, loggedIn: false };
    }

    case "clearAdminToken": {
      localStorage.removeItem("slate-player-web-app-admin-tokens");
      return { ...context, loggedInAsAdmin: false };
    }

    case "storeProfile": {
      return { ...context, profile: action.profile };
    }

    case "clearProfile": {
      return { ...context, profile: {} };
    }

    case "storeNewClub": {
      return { ...context, newClub: action.newClub };
    }

    case "clearNewClub": {
      return { ...context, newClub: {} };
    }

    case "storePlayers": {
      return { ...context, clubs: action.clubs };
    }

    case "clearPlayers": {
      return { ...context, clubs: [] };
    }

    case "clearSessionVariables": {
      return { ...context, currentPlayer: null, currentClub: null };
    }

    case "clearCurrentPlayer": {
      return { ...context, currentPlayer: {} };
    }

    case "showSelectPlayer": {
      return { ...context, showSelectPlayer: true };
    }

    case "closeSelectPlayer": {
      return { ...context, showSelectPlayer: false };
    }

    case "storeClubs": {
      return { ...context, clubs: action.clubs };
    }

    case "clearClubs": {
      return { ...context, clubs: [] };
    }

    case "clearCurrentClub": {
      return { ...context, currentClub: {} };
    }

    case "showSelectClub": {
      return { ...context, showSelectClub: true };
    }

    case "closeSelectClub": {
      return { ...context, showSelectClub: false };
    }

    case "storeEventTypes": {
      return { ...context, eventTypes: action.eventTypes };
    }

    case "clearEventTypes": {
      return { ...context, eventTypes: [] };
    }

    case "storeFeeCategories": {
      return { ...context, feeCategories: action.feeCategories };
    }

    case "clearFeeCategories": {
      return { ...context, feeCategories: [] };
    }

    default:
      return context;
  }
};
