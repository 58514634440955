import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";

import { getMembers as apiGetMembers, getTeamCaptains as apiGetTeamCaptains, addTeamCaptain as apiAddTeamCaptain, deleteTeamCaptain as apiDeleteTeamCaptain } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import Section from "../../components/section/Section";

import CaptainsTable from "../../tables/captainsTable/CaptainsTable";
import AddCaptainForm from "../../forms/addCaptainForm/AddCaptainForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function TeamPage({ history, match, classes, dispatch, context }) {
  const [showAddCaptain, toggleShowAddCaptain] = React.useState(false);
  // const [gettingMembers, setGettingMembers] = React.useState(true);
  const [members, setMembers] = React.useState([]);
  // const [gettingCaptains, setGettingCaptains] = React.useState(true);
  const [captains, setCaptains] = React.useState([]);
  // const [addingTeamCaptain, setAddingTeamCaptain] = React.useState(true);
  // const [addingTeamCaptainError, setAddTeamCaptainError] = React.useState(true);
  const [showDeleteTeamCaptain, setDeleteTeamCaptain] = React.useState(null);
  const [deletingTeamCaptain, setDeletingTeamCaptain] = React.useState(false);
  const [deleteTeamCaptainError, setDeleteTeamCaptainError] = React.useState(true);

  const getMembers = useCallback(async () => {
    try {
      if (!context.currentClub.clubId) {
        return false;
      }

      // setGettingMembers(true);
      const data = await apiGetMembers(context.currentClub.clubId, context.currentPlayer.id);
      if (data && !data.reason) {
        setMembers(data);
      }
      // setGettingMembers(false);
    } catch (e) {
      console.log(e);
      // setGettingMembers(false);
    }
  }, [context.currentClub.clubId, context.currentPlayer.id]);

  const getTeamCaptains = useCallback(async () => {
    try {
      // setGettingCaptains(true);
      const data = await apiGetTeamCaptains(context.currentClub.clubId, match.params.teamId, context.currentPlayer.id);
      if (data && !data.reason) {
        setCaptains(data);
      }
      // setGettingCaptains(false);
    } catch (e) {
      console.log(e);
      // setGettingCaptains(false);
    }
  }, [context.currentClub.clubId, context.currentPlayer.id, match.params.teamId]);

  const addTeamCaptain = async (member) => {
    try {
      // setAddingTeamCaptain(true);
      await apiAddTeamCaptain(context.currentClub.clubId, match.params.teamId, member.playerId);
      // setAddingTeamCaptain(false);
      toggleShowAddCaptain(false);
      getTeamCaptains();
    } catch (e) {
      // setAddingTeamCaptain(false);
      // setAddTeamCaptainError(e.message);
    }
  };

  const deleteTeamCaptain = async () => {
    try {
      setDeletingTeamCaptain(true);
      await apiDeleteTeamCaptain(context.currentClub.clubId, match.params.teamId, showDeleteTeamCaptain.id);
      setDeleteTeamCaptain(null);
      setDeletingTeamCaptain(null);
      getTeamCaptains();
    } catch (e) {
      setDeletingTeamCaptain(null);
      setDeleteTeamCaptainError(e.message);
    }
  };

  useEffect(() => {
    getMembers();
    getTeamCaptains();
  }, [getMembers, getTeamCaptains]);

  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true} title="Captains">
      <Section>
        <CaptainsTable captains={captains} addButtonText="Add captain" onAddButtonClick={() => toggleShowAddCaptain(true)} onDeleteTeamCaptain={(captain) => setDeleteTeamCaptain(captain)} />
      </Section>

      {showAddCaptain && (
        <DialogModal onClose={() => toggleShowAddCaptain(false)} error>
          <AddCaptainForm members={members} onAddCaptain={(member) => addTeamCaptain(member)} onClose={() => toggleShowAddCaptain(false)} />
        </DialogModal>
      )}

      {showDeleteTeamCaptain && (
        <YesNoDialogModal
          title="Are you sure you want to delete this member?"
          onClose={() => setDeleteTeamCaptain(false)}
          onSubmit={() => deleteTeamCaptain()}
          submitting={deletingTeamCaptain}
          error={deleteTeamCaptainError}
        />
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(TeamPage));
