import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  errorMessage: {
    color: "#222"
  }
});

function ErrorMessage({ classes, message }) {
  return <div className={classes.errorMessage}>{message}</div>;
}

export default withStyles(styles)(ErrorMessage);
