import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getClub as apiGetClub,
  updatePlayerInformation as apiUpdatePlayerInformation,
  updatePrivacyPolicy as apiUpdatePrivacyPolicy,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import Section from "../../components/section/Section";

import PlayerInformationForm from "../../forms/playerInformationForm/PlayerInformationForm";
import PrivacyPolicyForm from "../../forms/privacyPolicyForm/PrivacyPolicyForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paragraph: {
    marginBottom: 20,
  },
});

function PlayerInformationPage({ history, classes, context }) {
  // const [gettingClub, setGettingClub] = React.useState(false);
  const [club, setClub] = React.useState({});
  const [
    updatingPlayerInformation,
    setUpdatingPlayerInformation,
  ] = React.useState(false);
  const [
    updatePlayerInformationError,
    setUpdatePlayerInformationError,
  ] = React.useState(true);
  const [updatingPrivacyPolicy, setUpdatingPrivacyPolicy] = React.useState(
    false
  );
  const [
    updatePrivacyPolicyError,
    setUpdatePrivacyPolicyError,
  ] = React.useState(true);
  const [showAddPrivacyPolicy, toggleShowAddPrivacyPolicy] = React.useState(
    false
  );
  const [privacyPolicyAdded, setPrivacyPolicyAdded] = React.useState(false);

  const getClub = useCallback(async () => {
    try {
      // setGettingClub(true);
      const response = await apiGetClub(context.currentClub.clubId);
      setClub(response);
      // setGettingClub(false);
    } catch (e) {
      // setGettingClub(false);
    }
  }, [context.currentClub.clubId]);

  const updatePlayerInformation = async (payload) => {
    try {
      setUpdatingPlayerInformation(true);
      await apiUpdatePlayerInformation(context.currentClub.clubId, payload);

      if (
        Object.keys(payload).filter(
          (key) => payload[key] === 1 || payload[key] === 2
        ).length > 0 &&
        (club.latestClubPrivacyPolicy === null || privacyPolicyAdded)
      ) {
        toggleShowAddPrivacyPolicy(true);
      }

      setUpdatingPlayerInformation(false);
    } catch (e) {
      setUpdatingPlayerInformation(false);
      setUpdatePlayerInformationError(e.message);
    }
  };

  const updatePrivacyPolicy = async (payload) => {
    try {
      setUpdatingPrivacyPolicy(true);
      await apiUpdatePrivacyPolicy(context.currentClub.clubId, payload);
      if (payload && payload.length > 0) {
        setPrivacyPolicyAdded(true);
      }
      setUpdatingPrivacyPolicy(false);
    } catch (e) {
      setUpdatingPrivacyPolicy(false);
      setUpdatePrivacyPolicyError(e.message);
    }
  };

  useEffect(() => {
    getClub();
  }, [getClub]);

  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true}>
      <Section title="Player information">
        <div>
          <div className={classes.paragraph}>
            Your club may require players to provide additional information
            which assists in management of your club. Using the form below, you
            can control which information players are requested or required to
            provide. Please note that if you request players to provide any of
            the information below, you will also need to provide a Privacy
            Policy to your players. Please scroll on down the page to add and
            save your club's Privacy Policy.
          </div>
          <div className={classes.paragraph}>
            If you’d prefer not to ask your players for additional information,
            simply select the ‘Not Requested’ option for each of the details
            below.
          </div>
        </div>
        {club && club.clubId && (
          <PlayerInformationForm
            initialValues={club.playerInformationSettings}
            onSubmit={(clubDetailsPayload) => {
              updatePlayerInformation(clubDetailsPayload);
            }}
            submitting={updatingPlayerInformation}
            errorMessage={updatePlayerInformationError}
          />
        )}
      </Section>
      <Section title="Privacy policy">
        <div>
          <div className={classes.paragraph}>
            Under GDPR (General Data Protection Regulation), you are considered
            to be a 'data controller' as a result of holding information
            regarding players. In order to remain compliant with GDPR
            legislation, you should provide a Privacy Policy to your players,
            which informs them of your practices and policies when handling
            their data.
          </div>
          <div className={classes.paragraph}>
            The Privacy Policy you provide in the box below will be shown to
            players when they provide registration information to your club, and
            they will be required to consent to it before managing events and
            payments within your club.
          </div>
          <div className={classes.paragraph}>
            You are welcome to adapt our Privacy Policy template for use with
            your club, which can be downloaded from this{" "}
            <a href="https://my.slateapp.co.uk/docs/Slate-GDPR-Privacy-Policy-Template.docx">
              link
            </a>
            .
          </div>
          <div className={classes.paragraph}>
            Insert your Privacy Policy here
          </div>
        </div>
        {club && club.clubId && (
          <PrivacyPolicyForm
            initialValues={
              club.latestClubPrivacyPolicy &&
              club.latestClubPrivacyPolicy.privacyPolicy
            }
            onSubmit={(privacyPolicy) => {
              updatePrivacyPolicy(privacyPolicy);
            }}
            submitting={updatingPrivacyPolicy}
            errorMessage={updatePrivacyPolicyError}
          />
        )}
      </Section>

      {showAddPrivacyPolicy && (
        <DialogModal
          onClose={() => toggleShowAddPrivacyPolicy(false)}
          title="Add a privacy policy"
        >
          <div>
            As you are requesting information from your players, you are
            required to enter a privacy policy into the box below.
          </div>
        </DialogModal>
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(PlayerInformationPage));
