import React from "react";
import { withStyles } from "@material-ui/core/styles";

import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import ImagePicker from "../../components/imagePicker/ImagePicker";
import Button from "../../components/button/Button";
import RadioButtonSelector from "../../components/radioButtonSelector/RadioButtonSelector";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidClubName = (clubName) => !clubName;
const isInvalidAddress = (address) => !address;

const sportsOptions = [
  {
    label: "Football",
    value: "football",
  },
  {
    label: "Cricket",
    value: "cricket",
  },
  {
    label: "Rugby",
    value: "rugby",
  },
  {
    label: "Badminton",
    value: "badminton",
  },
  {
    label: "Athletics",
    value: "athletics",
  },
  {
    label: "Netball",
    value: "netball",
  },
  {
    label: "Hockey",
    value: "hockey",
  },
  {
    label: "Other",
    value: "other",
  },
];

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  formActions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

function EditClubDetailsForm({ classes, initialValues, onSubmit, errorMessage, submitting, submitButtonTitle }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState(initialValues || { address: {} });

  return (
    <div className={classes.section}>
      <SingleLineTextInput
        label="CLUB NAME*"
        value={payload.name}
        onChange={(value) => storePayload({ ...payload, name: value })}
        showInvalidInput={submitAttempted && isInvalidClubName(payload.name)}
      />
      <ImagePicker label="CLUB LOGO" value={payload.logo} onSelectImage={(value) => storePayload({ ...payload, logo: value.base64, logoFile: value.file })} />
      <SingleLineTextInput
        label="ADDRESS LINE 1*"
        value={payload.address.addressLineOne}
        onChange={(value) => storePayload({ ...payload, address: { ...payload.address, addressLineOne: value } })}
        showInvalidInput={submitAttempted && isInvalidAddress(payload.address.addressLineOne)}
      />
      <SingleLineTextInput
        label="ADDRESS LINE 2*"
        value={payload.address.addressLineTwo}
        onChange={(value) => storePayload({ ...payload, address: { ...payload.address, addressLineTwo: value } })}
        showInvalidInput={submitAttempted && isInvalidAddress(payload.address.addressLineTwo)}
      />
      <div>
        <SingleLineTextInput
          label="TOWN OR CITY*"
          value={payload.address.town}
          onChange={(value) => storePayload({ ...payload, address: { ...payload.address, town: value } })}
          showInvalidInput={submitAttempted && isInvalidAddress(payload.address.town)}
        />
        <SingleLineTextInput
          label="COUNTY"
          value={payload.address.county}
          payload={payload.address.county}
          onChange={(value) => storePayload({ ...payload, address: { ...payload.address, county: value } })}
        />
      </div>
      <SingleLineTextInput
        label="POSTCODE*"
        value={payload.address.postcode}
        onChange={(value) => storePayload({ ...payload, address: { ...payload.address, postcode: value } })}
        showInvalidInput={submitAttempted && isInvalidAddress(payload.address.postcode)}
      />
      <RadioButtonSelector items={sportsOptions} value={payload.sport} selectedItem={payload.sport} onSelect={(value) => storePayload({ ...payload, sport: value })} />
      <ErrorMessage message={errorMessage} />
      <div className={classes.formActions}>
        <Button
          title={submitButtonTitle || "Next"}
          onClick={() => {
            setSubmitAttempted(true);
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(EditClubDetailsForm);
