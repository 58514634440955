import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidName = (name) => !name;
const isInvalidEmail = (email) => !email;
const isInvalidConfirmEmail = (email, confirmEmail) => email !== confirmEmail;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditMemberForm({
  classes,
  payload,
  storePayload,
  onSubmit,
  errorMessage,
  submitting,
  feeCategories,
}) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Edit member" />
      </div>
      <SingleLineTextInput
        label="FIRST NAME"
        value={payload.firstName}
        onChange={(value) => storePayload({ ...payload, firstName: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.firstName)}
      />
      <SingleLineTextInput
        label="LAST NAME"
        value={payload.lastName}
        onChange={(value) => storePayload({ ...payload, lastName: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.lastName)}
      />
      <DropdownSelect
        label="FEE CATEGORY"
        value={payload.feeCategoryId}
        items={feeCategories.map((feeCategory) => {
          return { value: feeCategory.id, label: feeCategory.name };
        })}
        onSelect={(itemId) =>
          storePayload({ ...payload, feeCategoryId: itemId })
        }
      />
      <SingleLineTextInput
        label="EMAIL ADDRESS"
        value={payload.emailAddress}
        onChange={(value) => storePayload({ ...payload, emailAddress: value })}
        showInvalidInput={
          submitAttempted && isInvalidEmail(payload.emailAddress)
        }
        type="email"
      />
      <SingleLineTextInput
        label="CONFIRM EMAIL ADDRESS"
        onChange={(value) =>
          storePayload({ ...payload, confirmEmailAddress: value })
        }
        showInvalidInput={
          submitAttempted &&
          isInvalidConfirmEmail(
            payload.emailAddress,
            payload.confirmEmailAddress
          )
        }
        type="email"
      />
      <ErrorMessage message={errorMessage} />
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            setSubmitAttempted(true);
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(EditMemberForm);
