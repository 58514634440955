import React, { useEffect } from "react";
import { Switch, Route, Router, Redirect } from "react-router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  getProfile as apiGetProfile,
  refreshToken as apiRefreshToken,
  getOrganizationData as apiGetOrganizationData,
} from "./services/api";

import LoginPage from "./ui/pages/loginPage/LoginPage";
import RegisterPage from "./ui/pages/registerPage/RegisterPage";
import ForgotPasswordPage from "./ui/pages/forgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./ui/pages/resetPasswordPage/ResetPasswordPage";
import AcceptInvitePage from "./ui/pages/acceptInvitePage/AcceptInvitePage";
import VerifyAccountPage from "./ui/pages/verifyAccountPage/VerifyAccountPage";
import SplashPage from "./ui/pages/splashPage/SplashPage";
import ReportsPage from "./ui/pages/reportsPage/ReportsPage";
import ClubSettingsPage from "./ui/pages/clubSettingsPage/ClubSettingsPage";
import PlayerInformationPage from "./ui/pages/playerInformationPage/PlayerInformationPage";
import AccountSettingsPage from "./ui/pages/accountSettingsPage/AccountSettingsPage";
import TeamPage from "./ui/pages/teamPage/TeamPage";
import TeamsPage from "./ui/pages/teamsPage/TeamsPage";
// import MemberPage from "./ui/pages/memberPage/MemberPage";
import MembersPage from "./ui/pages/membersPage/MembersPage";
// import EventsPage from "./ui/pages/eventsPage/EventsPage";
import EventTypesPage from "./ui/pages/eventTypesPage/EventTypesPage";
import FeeCategoriesPage from "./ui/pages/feeCategoriesPage/FeeCategoriesPage";
import EventFeesPage from "./ui/pages/eventFeesPage/EventFeesPage";
import CreateClubPage from "./ui/pages/createClubPage/CreateClubPage";
import AuthorizeGoCardlessPage from "./ui/pages/authorizeGoCardlessPage/AuthorizeGoCardlessPage";

import AdminLoginPage from "./ui/pages/adminLoginPage/AdminLoginPage";
import AdminSelectUserPage from "./ui/pages/adminSelectUserPage/AdminSelectUserPage";

import { withState } from "./services/state";
import history from "./services/history";

const jwtDecode = require("jwt-decode");

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ababab",
      contrastText: "#fff",
    },
    secondary: {
      main: "#14a99d",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
      },
      containedSecondary: {
        color: "white",
      },
    },
  },
});

const init = async ({ dispatch, context }) => {
  const { loaded } = context;
  if (loaded) {
    return false;
  }

  let organization = window.location.hostname.split(".")[0];
  let organizationSlug = null;

  if (organization === "my" || organization === "localhost") {
    organizationSlug = "slate";
  } else if (organization) {
    organizationSlug = organization;
  } else {
    organizationSlug = "slate";
  }

  let siteLoadedPayload = {
    type: "siteLoaded",
    loaded: true,
  };

  let organizationData = null;

  try {
    organizationData = await apiGetOrganizationData(organizationSlug);

    const existingTokensStr = localStorage.getItem("slate-tokens");

    if (existingTokensStr) {
      let existingTokens = null;
      try {
        existingTokens = JSON.parse(existingTokensStr);

        const data = await apiRefreshToken(existingTokens.refreshToken);

        if (data.accessToken) {
          siteLoadedPayload.tokens = {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          };
          siteLoadedPayload.loggedIn = true;

          dispatch({
            type: "storeToken",
            tokens: {
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
            },
          });

          // Decode the tokens to see if there is a clubId and playerId
          let decodedAccessToken = jwtDecode(data.accessToken);

          const getProfileData = await apiGetProfile();

          siteLoadedPayload.profile = getProfileData;

          if (decodedAccessToken.playerId) {
            siteLoadedPayload.currentPlayer = getProfileData.players.find(
              (player) => player.id === decodedAccessToken.playerId
            );
          } else {
            let eligiblePlayers = getProfileData.players.filter(
              (player) =>
                player &&
                player.memberships &&
                player.memberships.filter(
                  (_) => _.organizationId === organizationData.guid
                ).length > 0
            );

            if (eligiblePlayers[0]) {
              siteLoadedPayload.currentPlayer =
                eligiblePlayers &&
                eligiblePlayers.length > 0 &&
                eligiblePlayers[0];
            } else {
              siteLoadedPayload.currentPlayer = getProfileData.players[0];
            }
          }

          if (decodedAccessToken.clubId && siteLoadedPayload.currentPlayer) {
            siteLoadedPayload.currentClub = siteLoadedPayload.currentPlayer.memberships
              .filter((_) => _.organizationId === organizationData.guid)
              .find(
                (membership) => membership.clubId === decodedAccessToken.clubId
              );
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  } catch (e) {
    console.log(e);
  }

  siteLoadedPayload.organizationData = organizationData;

  if (
    !siteLoadedPayload.currentClub &&
    siteLoadedPayload.currentPlayer &&
    siteLoadedPayload.currentPlayer.memberships &&
    siteLoadedPayload.currentPlayer.memberships.filter(
      (_) => _.organizationId === organizationData.guid
    ).length > 0
  ) {
    siteLoadedPayload.showSelectClub = true;
    dispatch(siteLoadedPayload);
  } else if (
    siteLoadedPayload.currentPlayer &&
    !siteLoadedPayload.currentClub &&
    !window.location.pathname.startsWith("/verify-account")
  ) {
    console.log(
      "TEST 123",
      siteLoadedPayload.currentPlayer,
      siteLoadedPayload.currentClub
    );
    dispatch(siteLoadedPayload);
    history.push("/create-club");
  } else if (
    siteLoadedPayload.currentClub &&
    !siteLoadedPayload.currentClub.setupComplete &&
    !window.location.pathname.startsWith("/club/merchant/authorize") &&
    !window.location.pathname.startsWith("/verify-account")
  ) {
    console.log(
      "TEST 456",
      siteLoadedPayload.currentPlayer,
      siteLoadedPayload.currentClub
    );
    dispatch(siteLoadedPayload);
    history.push("/create-club");
  } else if (
    siteLoadedPayload.currentClub &&
    window.location.pathname === "/"
  ) {
    history.push("/reports");
    dispatch(siteLoadedPayload);
  } else {
    dispatch(siteLoadedPayload);
  }
};

const App = ({ dispatch, context }) => {
  const { loggedIn, loggedInAsAdmin, loaded } = context;

  const Loading = ({ children }) =>
    !loaded ? <Switch>{children}</Switch> : null;
  const Unauthorized = ({ children }) =>
    loaded && !loggedIn && !loggedInAsAdmin ? (
      <Switch>{children}</Switch>
    ) : null;
  // const AuthorizedWithoutClub = ({ children }) =>
  //   loaded && loggedIn && currentClub && !currentClub.id ? (
  //     <Switch>{children}</Switch>
  //   ) : null;
  // const AuthorizedWithClub = ({ children }) =>
  //   loaded && loggedIn && currentClub && currentClub.id ? (
  //     <Switch>{children}</Switch>
  //   ) : null;
  const Authorized = ({ children }) =>
    loaded && loggedIn ? <Switch>{children}</Switch> : null;
  const AuthorizedAdmin = ({ children }) =>
    loaded && loggedInAsAdmin && !loggedIn ? <Switch>{children}</Switch> : null;

  // useEffect(() => {
  //   init({ dispatch, context });
  // }, []);

  useEffect(() => {
    init({ dispatch, context });
  }, [context, dispatch, loggedIn]);

  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="App">
            <Loading>
              <Route path="*" component={SplashPage} />
            </Loading>

            <Unauthorized>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/admin" component={AdminLoginPage} />
              <Route exact path="/register" component={RegisterPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/password-reset/:code"
                component={ResetPasswordPage}
              />
              <Route
                exact
                path="/accept-invite/:code"
                component={AcceptInvitePage}
              />
              <Route
                exact
                path="/verify-account/:code"
                component={VerifyAccountPage}
              />
              <Redirect from="*" to="/login" />
            </Unauthorized>

            {/* <AuthorizedWithoutClub>
              <Route exact path="/" component={SplashPage} />
              <Route exact path="/create-club" component={CreateClubPage} />
              <Route
                exact
                path="/verify-account/:code"
                component={VerifyAccountPage}
              />
              <Redirect from="*" to="/" />
            </AuthorizedWithoutClub> */}

            <Authorized>
              <Route exact path="/" component={SplashPage} />
              <Route exact path="/create-club" component={CreateClubPage} />
              <Route
                exact
                path="/verify-account/:code"
                component={VerifyAccountPage}
              />
              <Route
                exact
                path="/club/merchant/authorize"
                component={AuthorizeGoCardlessPage}
              />
              <Route exact path="/reports" component={ReportsPage} />
              <Route exact path="/club-settings" component={ClubSettingsPage} />
              <Route
                exact
                path="/player-information"
                component={PlayerInformationPage}
              />
              <Route
                exact
                path="/account-settings"
                component={AccountSettingsPage}
              />
              <Route exact path="/teams/:teamId" component={TeamPage} />
              <Route exact path="/teams" component={TeamsPage} />
              {/* <Route exact path="/members/:memberId" component={MemberPage} /> */}
              <Route exact path="/members" component={MembersPage} />
              {/* <Route exact path="/events" component={EventsPage} /> */}
              <Route exact path="/event-types" component={EventTypesPage} />
              <Route exact path="/fee-levels" component={FeeCategoriesPage} />
              <Route exact path="/event-fees" component={EventFeesPage} />
              <Redirect from="*" to="/reports" />
            </Authorized>

            <AuthorizedAdmin>
              <Route
                exact
                path="/select-player"
                component={AdminSelectUserPage}
              />
              <Redirect from="*" to="/select-player" />
            </AuthorizedAdmin>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default withState(App);
