import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import Button from "../../components/button/Button";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  clubsList: {
    marginBottom: 20,
  },
  selectClub: {
    padding: "10px",
    cursor: "pointer",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ececec",
    },
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
});

function SelectClubForm({
  classes,
  players,
  onLoginToClub,
  onGoToCreateClub,
  onSwitchPlayer,
  organizationId,
}) {
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Select a club" />
      </div>
      <div className={classes.clubsList}>
        {players &&
          players.map((player) => {
            return player.memberships
              .filter(
                (membership) =>
                  membership.organizationId === organizationId &&
                  membership.isTreasurer
              )
              .map((playerMembership) => (
                <div
                  style={{
                    opacity: playerMembership.isTreasurer ? 1 : 0.4,
                    cursor: playerMembership.isTreasurer
                      ? "pointer"
                      : "default",
                  }}
                  className={classes.selectClub}
                  onClick={() =>
                    playerMembership.isTreasurer &&
                    onLoginToClub({
                      club: playerMembership,
                      player,
                    })
                  }
                >
                  <div>{playerMembership.name}</div>
                </div>
              ));
          })}
      </div>

      <div className={classes.modalButtons}>
        <div>
          {onSwitchPlayer && (
            <Button
              title="Switch player"
              onClick={() => {
                onSwitchPlayer();
              }}
              style={{ backgroundColor: "rgb(243, 243, 243)", color: "#222" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SelectClubForm);
