import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../../components/formHeader/FormHeader";
import FormDescription from "../../../components/formDescription/FormDescription";
import Button from "../../../components/button/Button";

import TeamsTable from "../../../tables/teamsTable/TeamsTable";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  textSection: {
    marginBottom: 20,
  },
});

function SetupTeamsForm({
  classes,
  teams,
  onSubmit,
  submitting,
  onShowAddTeam,
  onEditTeam,
  onDeleteTeam,
}) {
  const renderTeams = () => {
    return (
      <TeamsTable
        hideControls={false}
        hideSearch={true}
        hideAddButton={true}
        teams={teams}
        onEditTeam={(team) => onEditTeam(team)}
        onDeleteTeam={(team) => onDeleteTeam(team)}
      />
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Setup your teams" />
        <FormDescription>
          <div className={classes.textSection}>
            Here you create your teams and select the players you would like to
            be able to do the following for that team:
            <div>
              <div>Create events/fixtures</div>
              <div>Select players</div>
              <div>Adjust match fees</div>
              <div>View who has/hasn't paid</div>
            </div>
          </div>
          <div className={classes.textSection}>
            Please get these player to watch the Captain’s tips video which is
            on our website. Here is a link to the{" "}
            <a href="https://slateapp.co.uk/get-started/">video</a>.
          </div>
        </FormDescription>
      </div>
      <div style={{ marginBottom: 30 }}>
        <Button
          title="Add new team"
          onClick={() => {
            onShowAddTeam();
          }}
          submitting={submitting}
        />
      </div>
      <div style={{ margin: "30px 0" }}>{renderTeams()}</div>
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            onSubmit(teams);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(SetupTeamsForm);
