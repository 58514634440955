import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

import CaptainsTable from "../../tables/captainsTable/CaptainsTable";

const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function AddTeamForm({ classes, payload, storePayload, onSubmit, errorMessage, submitting, members }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const onAddCaptain = (newCaptainId) => {
    let selectedMember = members.find((member) => {
      return member.playerId === newCaptainId;
    });

    let currentCaptains = payload.newCaptains;
    currentCaptains.push({
      ...selectedMember,
      player: {
        firstName: selectedMember.firstName,
        lastName: selectedMember.lastName,
      },
    });

    storePayload({ ...payload, newCaptains: currentCaptains });
  };

  const onDeleteCaptain = (captain) => {
    console.log("Delete current captain", captain);
    let currentCaptains = payload.newCaptains;

    for (let i = 0; i < currentCaptains.length; i++) {
      console.log(currentCaptains[i].memberId, captain.memberId);
      if (currentCaptains[i].memberId === captain.memberId) {
        currentCaptains.splice(i, 1);
        break;
      }
    }

    storePayload({ ...payload, newCaptains: currentCaptains });
  };

  console.log("CAPTAINS", payload.newCaptains);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Add team" />
      </div>
      <SingleLineTextInput label="NAME" onChange={(value) => storePayload({ ...payload, name: value })} showInvalidInput={submitAttempted && isInvalidName(payload.name)} />
      <div style={{ marginTop: 20, backgroundColor: "#f5f4f4", padding: 10 }}>
        <div>Captains</div>
        <CaptainsTable hideSearch={true} captains={payload.newCaptains} onDeleteCaptain={(captain) => onDeleteCaptain(captain)} />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div style={{ marginBottom: 20 }}>
            <DropdownSelect
              label="ADD A NEW CAPTAIN"
              items={members
                .filter((member) => payload.newCaptains.map((captain) => captain.memberId).indexOf(member.memberId) === -1)
                .map((member) => {
                  return { value: member.playerId, label: `${member.firstName} ${member.lastName}` };
                })}
              onSelect={(memberId) => storePayload({ ...payload, newCaptainId: memberId })}
            />
          </div>
          <Button
            title="Add captain"
            onClick={() => {
              onAddCaptain(payload.newCaptainId);
            }}
          />
        </div>
      </div>
      <ErrorMessage message={errorMessage} />
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            setSubmitAttempted(true);
            onSubmit();
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(AddTeamForm);
