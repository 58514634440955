import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SuccessMessage from "../../components/successMessage/SuccessMessage";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  twoFields: {
    display: "flex",
    width: "100%",
  },
  authLink: {
    margin: "10px 0",
  },
});

function ResetPasswordForm({ classes, message, error }) {
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Verify account" />
      </div>
      <ErrorMessage message={error} />
      <SuccessMessage message={message} />
    </div>
  );
}

export default withStyles(styles)(ResetPasswordForm);
