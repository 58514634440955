import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";

import Button from "../button/Button";

import Logo from "./Logo.png";

const styles = (theme) => ({
  authenticationFlowWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  authenticationFlow: {
    backgroundColor: "#fff",
    borderRadius: "3px",
    width: "100%",
    maxWidth: 1200,
    maxHeight: 800,
    height: "100%",
    overflow: "scroll",
    display: "flex",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  authenticationFlowSidebar: {
    backgroundColor: "#393A39",
    color: "#fff",
    width: 150,
    position: "relative",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  authenticationFlowLogo: {
    width: "100%",
    maxWidth: 100,
  },
  currentStep: {
    width: "calc(100% - 150px)",
    padding: 40,
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stepNumber: {
    color: "#9C9C9C",
    marginBottom: 20,
  },
  backButtons: {
    color: "#9C9C9C",
    position: "absolute",
    bottom: 20,
    left: 0,
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
  },
  stepTracker: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginBottom: "80px",
  },
  stepTrackerBar: {
    width: "100%",
    height: 3,
    backgroundColor: "#393a39",
  },
  stepTrackerBarProgress: {
    width: "100%",
    height: 3,
    backgroundColor: "#000",
  },
  stepTrackerBarCurrentProgress: {
    height: 3,
    backgroundColor: "#00823e",
    position: "absolute",
    zIndex: 50,
    left: 0,
    minWidth: 30,
  },
  stepTrackerSteps: {
    display: "flex",
    zIndex: 100,
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  stepTrackerStepNumber: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: "#00823e",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    marginTop: "14px",
  },
});

function renderCurrentStep(classes, currentStep) {
  return currentStep;
}

function AuthenticationFlow({
  dispatch,
  classes,
  style,
  currentStep,
  steps,
  onGoBack,
  onLogout,
  showStepTracker,
  onStepClick,
}) {
  const renderSteps = () => {
    let stepIndicators = [];

    for (let i = 0; i < steps.length; i++) {
      stepIndicators.push(
        <div
          onClick={() => i < currentStep && onStepClick(i)}
          style={{
            filter: i > currentStep ? "grayscale(100%)" : "none",
            cursor: i < currentStep ? "pointer" : "default",
          }}
        >
          <div className={classes.stepTrackerStepNumber}>{i + 1}</div>
          <div>Step {i + 1}</div>
        </div>
      );
    }

    return stepIndicators;
  };

  return (
    <div className={classes.authenticationFlowWrapper}>
      <div className={classes.authenticationFlow} style={style || {}}>
        <div className={classes.authenticationFlowSidebar}>
          <img
            className={classes.authenticationFlowLogo}
            alt="Slate logo"
            src={Logo}
          />
          <div className={classes.backButtons}>
            {onGoBack && (
              <Button
                style={{
                  backgroundColor: "rgb(243, 243, 243)",
                  color: "#222",
                  marginBottom: 10,
                }}
                title="Back"
                onClick={() => {
                  onGoBack();
                }}
              />
            )}
            {currentStep === 0 && onLogout && (
              <Button
                style={{ backgroundColor: "rgb(243, 243, 243)", color: "#222" }}
                title="Logout"
                onClick={() => {
                  onLogout();
                }}
              />
            )}
          </div>
        </div>
        <div className={classes.currentStep}>
          {!showStepTracker && steps.length > 1 && (
            <div className={classes.stepNumber}>Step {currentStep + 1}</div>
          )}
          {showStepTracker && steps.length > 1 && (
            <div className={classes.stepTracker}>
              <div className={classes.stepTrackerBar} />
              <div
                className={classes.stepTrackerBarCurrentProgress}
                style={{
                  width: `${(currentStep / (steps.length - 1)) * 100}%`,
                }}
              />
              <div className={classes.stepTrackerSteps}>{renderSteps()}</div>
            </div>
          )}
          {renderCurrentStep(classes, steps[currentStep])}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(withState(AuthenticationFlow));
