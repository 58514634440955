import React from "react";
import { withStyles } from "@material-ui/core/styles";

import MembershipTable from "../../tables/membershipTable/MembershipTable";
import FormHeader from "../../components/formHeader/FormHeader";
import FormDescription from "../../components/formDescription/FormDescription";
import Button from "../../components/button/Button";
import SelectFileButton from "../../components/selectFileButton/SelectFileButton";

import UploadExample from "./SlateTemplate.xls";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  uploadPlayer: {
    margin: "5px 0",
  },
  failedUploadReason: {
    fontStyle: "italic",
  },
});

function AddInitialPlayersForm({
  classes,
  members,
  onSubmit,
  submitting,
  onShowAddPlayer,
  onUploadSpreadsheet,
  uploadingSpreadsheet,
  uploadSuccessMessage,
  onEditMember,
  onEditUserEmail,
  onDeleteMember,
  errorMessage,
  successMessage,
}) {
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Players" />
        <FormDescription>
          <div>
            You're now ready to upload your club's players to Slate. There are
            two ways to add players:
          </div>
          <div>
            1) Spreadsheet: Simply input your players' details to the
            spreadsheet we provide and upload below. You can download the
            template you have to use <a href={UploadExample}>here</a>. It will
            be in your downloads folder.
          </div>
        </FormDescription>
        <div style={{ marginBottom: 30 }}>
          <SelectFileButton
            title="Add players via a Spreadsheet"
            onChange={(file) => {
              onUploadSpreadsheet(file);
            }}
            submitting={uploadingSpreadsheet}
          />
          {uploadSuccessMessage && (
            <div style={{ margin: "10px 0" }}>{uploadSuccessMessage}</div>
          )}
        </div>
        <FormDescription>
          <div>2) If you prefer, you may add your players one by one.</div>
        </FormDescription>
        <div style={{ marginBottom: 30 }}>
          <Button
            title="Add one-by-one"
            onClick={() => {
              onShowAddPlayer();
            }}
            submitting={submitting}
          />
        </div>
        <FormDescription>
          <div>
            Once you have set up a player, they will receive an email allowing
            them to setup their account, and register with your club. They will
            then be able to download and login into the Slate mobile app.
          </div>
        </FormDescription>
      </div>
      {members && (
        <div style={{ margin: "30px 0" }}>
          <MembershipTable
            hideSearch={true}
            members={members}
            reportType="playerStatusReport"
            onEditMember={(member) =>
              onEditMember({
                ...member,
                feeCategoryId: member.feeCategory && member.feeCategory.id,
              })
            }
            onEditUserEmail={(member) => onEditUserEmail(member)}
            onDeleteMember={(member) => onDeleteMember(member)}
          />
        </div>
      )}
      <Button
        title="Next"
        onClick={() => {
          onSubmit();
        }}
        submitting={submitting}
      />
    </div>
  );
}

export default withStyles(styles)(AddInitialPlayersForm);
