import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../../components/formHeader/FormHeader";
import FormDescription from "../../../components/formDescription/FormDescription";
import Button from "../../../components/button/Button";
import AddNewTextItem from "../../../components/addNewTextItem/AddNewTextItem";
import ErrorMessage from "../../../components/errorMessage/ErrorMessage";

import FeeCategoriesTable from "../../../tables/feeCategoriesTable/FeeCategoriesTable";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditFeesForm({
  classes,
  onSubmit,
  submitting,
  feeCategories,
  onCreateFeeCategory,
  errorMessage,
  onEditFeeCategory,
  onDeleteFeeCategory,
}) {
  const renderFeeCategories = () => {
    return (
      <FeeCategoriesTable
        hideSearch={true}
        feeCategories={feeCategories}
        onEditFeeCategory={(feeCategory) => onEditFeeCategory(feeCategory)}
        onDeleteFeeCategory={(feeCategory) => onDeleteFeeCategory(feeCategory)}
      />
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Fee levels" />
        <FormDescription>
          <div>
            Setup fee categories in order to charge different fees for the
            events that you have just setup in the previous step, for example:
          </div>
          <div>
            <div>Student</div>
            <div>Adult</div>
          </div>
          <div>You will fill in the price amounts in the next step.</div>
        </FormDescription>
      </div>
      <AddNewTextItem
        label="FEE CATEGORY*"
        buttonText="Add fee category"
        onClick={(payload) => onCreateFeeCategory(payload)}
      />
      <ErrorMessage message={errorMessage} />
      <div style={{ margin: "30px 0" }}>{renderFeeCategories()}</div>
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            onSubmit();
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(EditFeesForm);
