import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "../button/Button";
import ErrorMessage from "../errorMessage/ErrorMessage";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 36
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 80px)",
      marginLeft: 80,
      marginRight: 0
    }
  }
});

function YesNoDialogModal({ classes, title, onSubmit, onClose, submitting, error }) {
  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.root
        }
      }}
    >
      <DialogTitle id="create-task-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <div>{title}</div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </DialogContent>
      <DialogActions>
        {onClose && <Button title={"No"} onClick={() => onClose()} style={{ backgroundColor: "rgb(243, 243, 243)", color: "#222" }} />}
        {onSubmit && <Button title={"Yes"} onClick={() => onSubmit()} submitting={submitting} />}
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(YesNoDialogModal);
