import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Label from "../label/Label";
import Button from "../button/Button";

const styles = (theme) => ({
  imagePicker: {
    marginBottom: "10px",
  },
  imagePickerElements: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  logoThumbnail: {
    border: "1px solid #D7D7D7",
    borderRadius: "5px",
    height: 100,
    width: 100,
  },
});

function ImagePicker({ classes, label, value, onSelectImage }) {
  let upload = null;

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    if (FileReader && file) {
      var fr = new FileReader();
      fr.onload = function () {
        onSelectImage({ file, base64: fr.result });
      };
      fr.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.imagePicker}>
      <Label title={label} />
      <div className={classes.imagePickerElements}>
        <img src={value} className={classes.logoThumbnail} alt="Selected" />
        <Button
          title="Upload new photo"
          style={{ marginLeft: "16px", marginRight: "8px" }}
          onClick={() => {
            upload.click();
          }}
        />
        <Button title="Remove photo" style={{ marginLeft: "8px" }} onClick={() => onSelectImage({})} />
      </div>
      <input type="file" id="file" ref={(ref) => (upload = ref)} style={{ display: "none" }} onChange={(event) => onChangeFile(event)} />
    </div>
  );
}

export default withStyles(styles)(ImagePicker);
