import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidName = (name) => !name;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function AddEventTypeForm({ classes, payload, storePayload, onSubmit, errorMessage, submitting }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Add event type" />
      </div>
      <SingleLineTextInput label="NAME" onChange={(value) => storePayload({ ...payload, name: value })} showInvalidInput={submitAttempted && isInvalidName(payload.name)} />
      <ErrorMessage message={errorMessage} />
      {onSubmit && (
        <Button
          title="Next"
          onClick={() => {
            setSubmitAttempted(true);
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(AddEventTypeForm);
