import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

const playerStatusReportHeadings = [
  {
    label: "EMAIL",
    key: "emailAddress",
    tag: (data) => data.isTreasurer && "Treasurer",
  },
  {
    label: "FIRST NAME",
    key: "firstName",
  },
  {
    label: "LAST NAME",
    key: "lastName",
  },
  {
    label: "FEE CATEGORY",
    key: "feeCategory",
    subKey: "name",
  },
  {
    label: "HAS PLAYER JOINED SLATE",
    key: "hasAcceptedInvite",
    type: "yesNo",
  },
];

const playerPersonalInformationReportHeadings = [
  {
    label: "EMAIL",
    key: "emailAddress",
  },
  {
    label: "FIRST NAME",
    key: "firstName",
  },
  {
    label: "LAST NAME",
    key: "lastName",
  },
  {
    label: "DATE OF BIRTH",
    key: "playerInformation",
    subKey: "dateOfBirth",
    type: "date",
  },
  {
    label: "HOME ADDRESS",
    key: "playerInformation",
    subKey: "homeAddress",
  },
  {
    label: "MEDICAL CONDITIONS",
    key: "playerInformation",
    subKey: "medicalConditions",
  },
  {
    label: "EMERGENCY NAME",
    key: "playerInformation",
    subKey: "emergencyContactName",
  },
  {
    label: "EMERGENCY NUMBER",
    key: "playerInformation",
    subKey: "emergencyContactNumber",
  },
  {
    label: "HAS CONSENTED",
    key: "hasConsented",
    type: "yesNo",
  },
];

function PlayerRegistrationStatusTable({
  classes,
  reportType,
  members,
  addButtonText,
  onAddButtonClick,
  hideSearch,
  onEditMember,
  onEditUserEmail,
  onDeleteMember,
  additionalButtons,
  filterSelects,
  showDownloadButton,
}) {
  if (reportType === "playerStatusReport") {
    return (
      <div className={classes.section}>
        <Table
          hideSearch={hideSearch}
          addButtonText={addButtonText}
          headings={playerStatusReportHeadings}
          data={members}
          onAddButtonClick={(payload) => onAddButtonClick(payload)}
          rowActions={[
            {
              type: "edit",
              onClick: onEditMember ? (member) => onEditMember(member) : null,
            },
            {
              type: "email",
              onClick: onEditUserEmail
                ? (member) => onEditUserEmail(member)
                : null,
            },
            {
              type: "delete",
              onClick: onDeleteMember
                ? (member) => onDeleteMember(member)
                : null,
            },
          ]}
          additionalButtons={additionalButtons}
          filterSelects={filterSelects}
          showDownloadButton={showDownloadButton}
          downloadFilename="playerStatusReport"
        />
      </div>
    );
  } else if (reportType === "playerPersonalInformationReport") {
    return (
      <div className={classes.section}>
        <Table
          hideSearch={hideSearch}
          addButtonText={addButtonText}
          headings={playerPersonalInformationReportHeadings}
          data={members}
          onAddButtonClick={(payload) => onAddButtonClick(payload)}
          rowActions={[
            {
              type: "edit",
              onClick: onEditMember ? (member) => onEditMember(member) : null,
            },
            {
              type: "email",
              onClick: onEditUserEmail
                ? (member) => onEditUserEmail(member)
                : null,
            },
            {
              type: "delete",
              onClick: onDeleteMember
                ? (member) => onDeleteMember(member)
                : null,
            },
          ]}
          additionalButtons={additionalButtons}
          filterSelects={filterSelects}
          showDownloadButton={showDownloadButton}
          downloadFilename="playerPersonalInformationReport"
        />
      </div>
    );
  }

  return <div />;
}

export default withStyles(styles)(PlayerRegistrationStatusTable);
