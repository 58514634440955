import React from "react";
import { withStyles } from "@material-ui/core/styles";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
// import DialogModal from "../../components/dialogModal/DialogModal";
// import Section from "../../components/section/Section";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

function AccountSettingsPage({ history, classes }) {
  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true} title="Club settings">
      {/* <DialogModal /> */}
    </PageWrapper>
  );
}

export default withStyles(styles)(AccountSettingsPage);
