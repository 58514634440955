import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "../../components/button/Button";
import MultiLineTextInput from "../../components/multiLineTextInput/MultiLineTextInput";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  formActions: {
    textAlign: "right",
  },
});

function PrivacyPolicyForm({
  classes,
  initialValues,
  onSubmit,
  submitting,
  errorMessage,
}) {
  const [payload, storePayload] = React.useState(initialValues || null);

  return (
    <div className={classes.section}>
      <MultiLineTextInput
        value={payload}
        onChange={(value) => storePayload(value)}
        style={{ height: 500 }}
      />
      <ErrorMessage message={errorMessage} />
      <div className={classes.formActions}>
        <Button
          title="Save"
          onClick={() => {
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(PrivacyPolicyForm);
