import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "../button/Button";

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 36,
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 80px)",
      marginLeft: 80,
      marginRight: 0,
    },
  },
});

function DialogModal({ classes, children, title, submitText, onSubmit, submitting, cancelText, onClose, hideCancelButton }) {
  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle id="create-task-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onClose && !hideCancelButton && <Button title={cancelText ? cancelText : "Cancel"} onClick={() => onClose()} style={{ backgroundColor: "rgb(243, 243, 243)", color: "#222" }} />}
        {onSubmit && <Button title={submitText} onClick={() => onSubmit()} submitting={submitting} />}
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(DialogModal);
