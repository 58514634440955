import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  errorMessage: {
    color: "red",
    marginBottom: "20px",
  },
});

function ErrorMessage({ classes, message }) {
  return <div className={classes.errorMessage}>{message}</div>;
}

export default withStyles(styles)(ErrorMessage);
