import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  createClub as apiCreateClub,
  updateClub as apiUpdateClub,
  updateClubSetupProgress as apiUpdateClubSetupProgress,
  getLogoUploadUrl as apiGetLogoUploadUrl,
  uploadLogo as apiUploadLogo,
  loginToClub as apiLoginToClub,
  getProfile as apiGetProfile,
  getEventTypes as apiGetEventTypes,
  createEventType as apiCreateEventType,
  updateEventType as apiUpdateEventType,
  deleteEventType as apiDeleteEventType,
  getFeeCategories as apiGetFeeCategories,
  createFeeCategory as apiCreateFeeCategory,
  updateFeeCategory as apiUpdateFeeCategory,
  deleteFeeCategory as apiDeleteFeeCategory,
  getEventFees as apiGetEventFees,
  // createEventFee as apiCreateEventFee,
  // updateEventFee as apiUpdateEventFee,
  updateAllEventFees as apiUpdateAllEventFees,
  getMembers as apiGetMembers,
  createMember as apiCreateMember,
  uploadSpreadsheet as apiUploadSpreadsheet,
  updateMember as apiUpdateMember,
  updateUserEmail as apiUpdateUserEmail,
  resendInvitationEmail as apiResendInvitationEmail,
  deleteMember as apiDeleteMember,
  getTeams as apiGetTeams,
  createTeam as apiCreateTeam,
  addTeamCaptain as apiAddTeamCaptain,
  deleteTeam as apiDeleteTeam,
  deleteCaptain as apiDeleteCaptain,
  updateTeam as apiUpdateTeam,
  createGoCardlessMerchant as apiCreateGoCardlessMerchant,
  updateMemberFeeCategory as apiUpdateMemberFeeCategory,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";

import EditClubDetailsForm from "../../forms/editClubDetailsForm/EditClubDetailsForm";
import EditEventTypesForm from "../../forms/clubSetupForms/editEventTypesForm/EditEventTypesForm";
import EditEventTypeForm from "../../forms/editEventTypeForm/EditEventTypeForm";
import EditFeesForm from "../../forms/clubSetupForms/editFeesForm/EditFeesForm";
import EditEventFeesForm from "../../forms/editEventFeesForm/EditEventFeesForm";
import EditFeeCategoryForm from "../../forms/editFeeCategoryForm/EditFeeCategoryForm";
import AddInitialPlayersForm from "../../forms/addInitialPlayersForm/AddInitialPlayersForm";
import AddMemberForm from "../../forms/addMemberForm/AddMemberForm";
import UploadSpreadsheetForm from "../../forms/uploadSpreadsheetForm/UploadSpreadsheetForm";
import EditMemberForm from "../../forms/editMemberForm/EditMemberForm";
import EditUserEmailForm from "../../forms/editUserEmailForm/EditUserEmailForm";
import AddTeamForm from "../../forms/addTeamForm/AddTeamForm";
import EditTeamForm from "../../forms/editTeamForm/EditTeamForm";
import SetupTeamsForm from "../../forms/clubSetupForms/setupTeamsForm/SetupTeamsForm";
import SetupGoCardlessForm from "../../forms/clubSetupForms/setupGoCardlessForm/SetupGoCardlessForm";

const styles = (theme) => ({
  uploadPlayer: {
    margin: "5px 0",
  },
  failedUploadReason: {
    fontStyle: "italic",
  },
});

const jwtDecode = require("jwt-decode");

const isInvalidEmail = (email) => !email;
const isInvalidConfirmEmail = (email, confirmEmail) => email !== confirmEmail;

const CreateClub = ({ history, dispatch, context, classes }) => {
  const [currentStep, setCurrentStep] = React.useState(
    context.currentClub.currentSetupStep || 0
  );
  const [showUploadSpreadsheet, toggleShowUploadSpreadsheet] = React.useState(
    false
  );
  const [creatingOrUpdatingClub, setCreatingOrUpdatingClub] = React.useState(
    false
  );
  const [createClubError, setCreateClubError] = React.useState("");
  const [creatingEventType, setCreatingEventType] = React.useState(false);
  const [createEventTypeError, setCreateEventTypeError] = React.useState("");
  const [editEventType, setEditEventType] = React.useState(null);
  const [updatingEventType, setUpdatingEventType] = React.useState(false);
  const [updateEventTypeError, setUpdateEventTypeError] = React.useState("");
  const [showDeleteEventType, setDeleteEventType] = React.useState(null);
  const [deletingEventType, setDeletingEventType] = React.useState(false);
  const [deleteEventTypeError, setDeleteEventTypeError] = React.useState("");
  const [eventTypes, setEventTypes] = React.useState([]);
  const [creatingFeeCategory, setCreatingFeeCategory] = React.useState(false);
  const [createFeeCategoryError, setCreatefeeCategoryError] = React.useState(
    ""
  );
  const [editFeeCategory, setEditFeeCategory] = React.useState(null);
  const [updatingFeeCategory, setUpdatingFeeCategory] = React.useState(false);
  const [updateFeeCategoryError, setUpdateFeeCategoryError] = React.useState(
    ""
  );
  const [showDeleteFeeCategory, setDeleteFeeCategory] = React.useState(null);
  const [deletingFeeCategory, setDeletingFeeCategory] = React.useState(false);
  const [deleteFeeCategoryError, setDeleteFeeCategoryError] = React.useState(
    ""
  );
  const [feeCategories, setFeeCategories] = React.useState([]);
  // const [gettingEventFees, setGettingEventFees] = React.useState(false);
  const [eventFees, setEventFees] = React.useState([]);
  // const [creatingOrUpdatingEventFee, setCreatingOrUpdatingEventFee] = React.useState(false);
  // const [createOrUpdateEventFeeError, setCreateOrUpdateEventFeeError] = React.useState("");
  const [updatingAllEventFees, setUpdatingAllEventFees] = React.useState(false);
  const [
    updatingAllEventFeesError,
    setUpdatingAllEventFeesError,
  ] = React.useState("");
  const [showAddMember, toggleShowAddPlayer] = React.useState(false);
  const [showMemberAdded, toggleShowMemberAdded] = React.useState(false);
  const [newMember, storeNewMember] = React.useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    confirmEmailAddress: "",
  });
  const [members, setMembers] = React.useState([]);
  const [createMemberError, setCreateMemberError] = React.useState("");
  // const [
  //   createMemberSuccessMessage,
  //   setCreateMemberSuccessMessage,
  // ] = React.useState(true);
  const [creatingMember, setCreatingMember] = React.useState(false);
  const [uploadingSpreadsheet, setUploadingSpreadsheet] = React.useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = React.useState(null);
  const [unsuccessfulUploads, setUnsuccessfulUploads] = React.useState([]);
  const [editMember, setEditMember] = React.useState(null);
  const [updatingMember, setUpdatingMember] = React.useState(false);
  const [updateMemberError, setUpdateMemberError] = React.useState(true);
  const [editUserEmail, setEditUserEmail] = React.useState(null);
  const [updatingUserEmail, setUpdatingUserEmail] = React.useState(false);
  const [updateUserEmailError, setUpdateUserEmailError] = React.useState(true);
  const [
    resendingInvitationEmail,
    setResendingInvitationEmail,
  ] = React.useState(false);
  const [showDeleteMember, setDeleteMember] = React.useState(null);
  const [deletingMember, setDeletingMember] = React.useState(false);
  const [deleteMemberError, setDeleteMemberError] = React.useState(true);
  const [showAddTeam, toggleShowAddTeam] = React.useState(false);
  const [newTeam, storeNewTeam] = React.useState({
    newCaptains: [],
  });
  // const [gettingTeams, setGettingTeams] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  const [creatingTeam, setCreatingTeam] = React.useState(false);
  const [createTeamError, setCreateTeamError] = React.useState("");
  const [editTeam, setEditTeam] = React.useState(null);
  const [updatingTeam, setUpdatingTeam] = React.useState(false);
  const [updateTeamError, setUpdateTeamError] = React.useState(true);
  const [showDeleteTeam, setDeleteTeam] = React.useState(null);
  const [deletingTeam, setDeletingTeam] = React.useState(false);
  const [showDeleteCaptain, setDeleteCaptain] = React.useState(null);
  const [addingCaptain, setAddingCaptain] = React.useState(false);
  const [deletingCaptain, setDeletingCaptain] = React.useState(false);
  const [deleteTeamError, setDeleteTeamError] = React.useState("");
  const [deleteCaptainError, setDeleteCaptainError] = React.useState("");
  const [goCardlessUrl, setGoCardlessUrl] = React.useState(null);

  const createOrUpdateClub = async (payload) => {
    if (
      !payload.name ||
      !payload.sport ||
      !payload.address.addressLineOne ||
      !payload.address.addressLineTwo ||
      !payload.address.town ||
      !payload.address.postcode
    ) {
      return setCreateClubError("Please complete all fields");
    }

    // if (!payload.logo || !payload.logo.startsWith("data:image")) {
    //   return setCreateClubError("Please select a logo");
    // }

    setCreateClubError("");

    try {
      setCreatingOrUpdatingClub(true);

      if (!context.currentClub.clubId) {
        const data = await apiCreateClub(
          payload,
          context.currentPlayer.id,
          context.organizationData.guid
        );

        if (data) {
          const getProfileData = await apiGetProfile();

          const loginToClubData = await apiLoginToClub(
            data,
            context.currentPlayer.id
          );

          if (loginToClubData && loginToClubData.accessToken) {
            // Decode the tokens to see if there is a clubId and playerId
            let decodedAccessToken = jwtDecode(loginToClubData.accessToken);

            const currentPlayer = getProfileData.players.find(
              (player) => player.id === decodedAccessToken.playerId
            );
            const currentClub = currentPlayer.memberships.find(
              (membership) => membership.clubId === decodedAccessToken.clubId
            );

            dispatch({
              type: "storeSessionVariables",
              currentPlayer,
              currentClub,
              tokens: {
                accessToken: loginToClubData.accessToken,
                refreshToken: loginToClubData.refreshToken,
              },
            });

            if (payload.logo && payload.logo.startsWith("data:image")) {
              const getLogoUploadUrlResponse = await apiGetLogoUploadUrl();
              await apiUploadLogo(getLogoUploadUrlResponse, payload.logoFile);
            }
          }
        }
      } else {
        await apiUpdateClub(context.currentClub.clubId, payload);
        if (payload.logo && payload.logo.startsWith("data:image")) {
          const getLogoUploadUrlResponse = await apiGetLogoUploadUrl();
          await apiUploadLogo(getLogoUploadUrlResponse, payload.logoFile);
        }
      }

      setCurrentStep(currentStep + 1);
      apiUpdateClubSetupProgress(context.currentClub.clubId, currentStep + 1);
      setCreatingOrUpdatingClub(false);
    } catch (e) {
      console.log(e);
      setCreatingOrUpdatingClub(false);

      if (e.message) {
        setCreateClubError(e.message);
        return false;
      }
      setCreateClubError("An error has occurred");
    }
  };

  const getEventTypes = useCallback(async () => {
    try {
      const data = await apiGetEventTypes(context.currentClub.clubId);
      if (data) {
        setEventTypes(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub.clubId]);

  const createEventType = async (payload) => {
    if (!payload.newItemText) {
      return setCreateEventTypeError("Please complete all fields");
    }

    setCreateEventTypeError("");

    try {
      setCreatingEventType(true);
      await apiCreateEventType(context.currentClub.clubId, {
        name: payload.newItemText,
      });
      getEventTypes();
      setCreatingEventType(false);
    } catch (e) {
      setCreatingEventType(false);
      setCreateEventTypeError(e.message);
    }
  };

  const updateEventType = async (payload) => {
    if (!payload.name) {
      return setUpdateEventTypeError("Please enter an event type name");
    }

    setUpdateEventTypeError("");

    try {
      setUpdatingEventType(true);
      await apiUpdateEventType(context.currentClub.clubId, payload.id, payload);
      setEditEventType(null);
      setUpdatingEventType(false);
      getEventTypes();
    } catch (e) {
      setUpdatingEventType(false);
      setUpdateEventTypeError(e.message);
    }
  };

  const deleteEventType = async () => {
    try {
      setDeletingEventType(true);
      await apiDeleteEventType(
        context.currentClub.clubId,
        showDeleteEventType.id
      );
      setDeletingEventType(false);
      setDeleteEventType(null);
      getEventTypes();
    } catch (e) {
      setDeletingEventType(false);
      setDeleteEventTypeError(e.message);
    }
  };

  const getFeeCategories = useCallback(async () => {
    try {
      const data = await apiGetFeeCategories(context.currentClub.clubId);
      if (data) {
        setFeeCategories(data);

        if (data && data.length === 1) {
          await apiUpdateMemberFeeCategory(
            context.currentClub.clubId,
            context.currentClub.id,
            data[0].id
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub.clubId, context.currentClub.id]);

  const createFeeCategory = async (payload) => {
    if (!payload.newItemText) {
      return setCreatefeeCategoryError("Please complete all fields");
    }

    setCreatefeeCategoryError("");

    try {
      setCreatingFeeCategory(true);
      await apiCreateFeeCategory(context.currentClub.clubId, {
        name: payload.newItemText,
      });
      getFeeCategories();
      setCreatingFeeCategory(false);
    } catch (e) {
      setCreatingFeeCategory(false);
      setCreatefeeCategoryError(e.message);
    }
  };

  const updateFeeCategory = async (payload) => {
    if (!payload.name) {
      return setUpdateFeeCategoryError("Please enter a fee category name");
    }

    setUpdateFeeCategoryError("");

    try {
      setUpdatingFeeCategory(true);
      await apiUpdateFeeCategory(
        context.currentClub.clubId,
        payload.id,
        payload
      );
      setEditFeeCategory(null);
      setUpdatingFeeCategory(false);
      getFeeCategories();
    } catch (e) {
      setUpdatingFeeCategory(false);
      setUpdateFeeCategoryError(e.message);
    }
  };

  const deleteFeeCategory = async () => {
    try {
      setDeletingFeeCategory(true);
      await apiDeleteFeeCategory(
        context.currentClub.clubId,
        showDeleteFeeCategory.id
      );
      setDeletingFeeCategory(false);
      setDeleteFeeCategory(null);
      getFeeCategories();
    } catch (e) {
      setDeletingFeeCategory(false);
      setDeleteFeeCategoryError(e.message);
    }
  };

  const getEventFees = useCallback(async () => {
    if (
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingEventFees(true);
      const data = await apiGetEventFees(context.currentClub.clubId);
      if (data && !data.reason) {
        setEventFees(data);
      }
      // setGettingEventFees(false);
    } catch (e) {
      console.log(e);
      // setGettingEventFees(false);
    }
  }, [context.currentClub.clubId, context.currentPlayer]);

  const getEventFee = (eventTypeId, feeCategoryId) => {
    return eventFees.find((eventFee) => {
      return (
        eventFee.eventType &&
        eventFee.eventType.id === eventTypeId &&
        eventFee.feeCategory &&
        eventFee.feeCategory.id === feeCategoryId
      );
    });
  };

  const updateEventFee = (eventTypeId, feeCategoryId, value) => {
    let currentEventFees = eventFees.map((eventFee) => eventFee);
    let currentEventFee = currentEventFees.find((eventFee) => {
      return (
        eventFee.eventType &&
        eventFee.eventType.id === eventTypeId &&
        eventFee.feeCategory &&
        eventFee.feeCategory.id === feeCategoryId
      );
    });

    if (!currentEventFee) {
      currentEventFees.push({
        eventType: {
          id: eventTypeId,
        },
        feeCategory: {
          id: feeCategoryId,
        },
        defaultFee: value,
      });
    } else {
      currentEventFee.defaultFee = value;
    }

    setEventFees(currentEventFees);
  };

  // const createOrUpdateEventFee = async (payload) => {
  //   if (payload.defaultFee === "" || payload.defaultFee === null || payload.defaultFee === undefined || isNaN(payload.defaultFee)) {
  //     return setCreateOrUpdateEventFeeError("Please enter a valid number");
  //   }

  //   setCreateOrUpdateEventFeeError("");

  //   try {
  //     setCreatingOrUpdatingEventFee(true);

  //     const existingEventFee = getEventFee(payload.eventTypeId, payload.feeCategoryId);

  //     if (existingEventFee) {
  //       await apiUpdateEventFee(context.currentClub.clubId, existingEventFee.id, payload);
  //     } else {
  //       await apiCreateEventFee(context.currentClub.clubId, payload);
  //     }

  //     setEditEventFee(null);
  //     setCreatingOrUpdatingEventFee(false);
  //     getEventFees();
  //   } catch (e) {
  //     setCreatingOrUpdatingEventFee(false);
  //     setCreateOrUpdateEventFeeError(e.message);
  //   }
  // };

  const updateAllEventFees = async () => {
    for (let i = 0; i < eventTypes.length; i++) {
      for (let j = 0; j < feeCategories.length; j++) {
        const existingEventFee = getEventFee(
          eventTypes[i].id,
          feeCategories[j].id
        );

        if (
          !existingEventFee ||
          existingEventFee.defaultFee === null ||
          existingEventFee.defaultFee === undefined ||
          existingEventFee.defaultFee === ""
        ) {
          setUpdatingAllEventFeesError(
            "Please assign values for all event fees"
          );
          return false;
        }
      }
    }

    try {
      setUpdatingAllEventFees(true);

      await apiUpdateAllEventFees(
        context.currentClub.clubId,
        eventFees.map((eventFee) => {
          return {
            id: eventFee.id,
            feeCategoryId: eventFee.feeCategory.id,
            eventTypeId: eventFee.eventType.id,
            defaultFee: eventFee.defaultFee,
          };
        })
      );
      await getEventFees();
      setCurrentStep(currentStep + 1);
      apiUpdateClubSetupProgress(context.currentClub.clubId, currentStep + 1);

      setUpdatingAllEventFees(false);
    } catch (e) {
      setUpdatingAllEventFees(false);
      setUpdatingAllEventFeesError(e.message);
    }
  };

  const getMembers = useCallback(async () => {
    try {
      if (!context.currentClub.clubId) {
        return false;
      }

      const data = await apiGetMembers(context.currentClub.clubId);
      if (data) {
        setMembers(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub.clubId]);

  const createMember = async (payload) => {
    if (
      !payload.firstName ||
      !payload.lastName ||
      !payload.emailAddress ||
      !payload.feeCategoryId
    ) {
      setCreateMemberError("Please complete all fields");
      return false;
    }

    if (payload.emailAddress !== payload.confirmEmailAddress) {
      setCreateMemberError("Email addresses do not match");
      return false;
    }

    setCreateMemberError("");

    try {
      setCreatingMember(true);
      const memberId = await apiCreateMember(context.currentClub.clubId, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        emailAddress: payload.emailAddress.toLowerCase(),
      });
      await apiUpdateMemberFeeCategory(
        context.currentClub.clubId,
        memberId,
        payload.feeCategoryId
      );

      setCreatingMember(false);
      // toggleShowAddPlayer(false);
      storeNewMember({
        firstName: "",
        lastName: "",
        emailAddress: "",
        confirmEmailAddress: "",
      });
      toggleShowAddPlayer(false);
      toggleShowMemberAdded(true);
      getMembers();
    } catch (e) {
      setCreatingMember(false);
      setCreateMemberError(e.message);
    }
  };

  const uploadSpreadsheet = async (file) => {
    setUploadingSpreadsheet(true);
    const data = await apiUploadSpreadsheet(context.currentClub.clubId, file);
    if (data) {
      setUploadSuccessMessage(
        `Upload completed. ${data.successfulUploadCount} players uploaded successfully.`
      );

      if (data.unsuccessfulUploads) {
        setUnsuccessfulUploads(data.unsuccessfulUploads);
      }
    }
    setUploadingSpreadsheet(false);
    getMembers();
  };

  const updateMember = async (payload) => {
    if (!payload.firstName || !payload.lastName || !payload.feeCategoryId) {
      setUpdateMemberError("Please complete all fields");
      return false;
    }

    setUpdateMemberError("");

    try {
      setUpdatingMember(true);
      await apiUpdateMember(context.currentClub.clubId, payload);
      await apiUpdateMemberFeeCategory(
        context.currentClub.clubId,
        payload.memberId,
        payload.feeCategoryId
      );
      await updateUserEmail(payload);
      setEditMember(null);
      setUpdatingMember(null);
      getMembers();
    } catch (e) {
      setUpdatingMember(null);
      setUpdateMemberError(e.message);
    }
  };

  const updateUserEmail = async (payload) => {
    if (isInvalidEmail(payload.emailAddress)) {
      return setUpdateUserEmailError("Please enter an email address");
    }

    if (
      isInvalidConfirmEmail(payload.emailAddress, payload.confirmEmailAddress)
    ) {
      return setUpdateUserEmailError("Email address do not match");
    }

    try {
      setUpdateUserEmailError("");
      setUpdatingUserEmail(true);
      await apiUpdateUserEmail(context.currentClub.clubId, payload);
      setEditUserEmail(null);
      setUpdatingUserEmail(null);
      getMembers();
    } catch (e) {
      setUpdatingMember(null);
      setUpdateUserEmailError(e.message);
    }
  };

  const resendInvitationEmail = async (payload) => {
    try {
      setUpdateUserEmailError("");
      setResendingInvitationEmail(true);
      await apiResendInvitationEmail(context.currentClub.clubId, payload);
      setEditUserEmail(null);
      setResendingInvitationEmail(null);
      getMembers();
    } catch (e) {
      setResendingInvitationEmail(null);
    }
  };

  const deleteMember = async () => {
    try {
      setDeletingMember(true);
      await apiDeleteMember(
        context.currentClub.clubId,
        showDeleteMember.memberId
      );
      setDeleteMember(null);
      setDeletingMember(null);
      getMembers();
    } catch (e) {
      setDeletingMember(null);
      setDeleteMemberError(e.message);
    }
  };

  const getTeams = useCallback(async () => {
    if (!context.currentPlayer || !context.currentPlayer.id) {
      return false;
    }

    try {
      // setGettingTeams(true);
      const data = await apiGetTeams(context.currentClub.clubId);
      if (data && !data.reason) {
        setTeams(data);
      }
      // setGettingTeams(false);
    } catch (e) {
      console.log(e);
      // setGettingTeams(false);
    }
  }, [context.currentClub.clubId, context.currentPlayer]);

  const createTeam = async (payload) => {
    if (!payload.name) {
      return setCreateTeamError("Please complete all fields");
    }

    setCreateTeamError("");

    try {
      setCreatingTeam(true);
      const teamId = await apiCreateTeam(context.currentClub.clubId, {
        name: payload.name,
        playerId: context.currentPlayer.id,
      });

      payload.newCaptains.forEach(async (newCaptain) => {
        await apiAddTeamCaptain(
          context.currentClub.clubId,
          teamId,
          newCaptain.playerId
        );
      });

      setCreatingTeam(false);
      toggleShowAddTeam(false);
      getTeams();
    } catch (e) {
      setCreatingTeam(false);
      setCreateTeamError(e.message);
    }
  };

  const updateTeam = async (payload) => {
    if (!payload.name) {
      return setUpdateTeamError("Please enter a team name");
    }

    setUpdateTeamError("");

    try {
      setUpdatingTeam(true);
      await apiUpdateTeam(context.currentClub.clubId, payload.id, payload);
      setEditTeam(null);
      setUpdatingTeam(false);
      getTeams();
    } catch (e) {
      setUpdatingTeam(false);
      setUpdateTeamError(e.message);
    }
  };

  const deleteTeam = async () => {
    try {
      setDeletingTeam(true);
      await apiDeleteTeam(
        context.currentClub.clubId,
        showDeleteTeam.id,
        context.currentPlayer.id
      );
      setDeletingTeam(false);
      setDeleteTeam(null);
      getTeams();
    } catch (e) {
      setDeletingTeam(false);
      setDeleteTeamError(e.message);
    }
  };

  const addCaptain = async (captainId) => {
    try {
      setAddingCaptain(true);
      await apiAddTeamCaptain(
        context.currentClub.clubId,
        editTeam.id,
        captainId
      );
      getTeams();
      setAddingCaptain(false);
      setEditTeam(null);
      getTeams();
    } catch (e) {
      setAddingCaptain(false);
      setUpdateTeamError(e.message);
    }
  };

  const deleteCaptain = async () => {
    try {
      setDeletingCaptain(true);
      await apiDeleteCaptain(
        context.currentClub.clubId,
        editTeam.id,
        showDeleteCaptain.guid
      );
      getTeams();
      setDeletingCaptain(false);
      setDeleteCaptain(null);
      setEditTeam(null);
      getTeams();
    } catch (e) {
      setDeletingCaptain(false);
      setDeleteCaptainError(e.message);
    }
  };

  const createGoCardlessMerchant = useCallback(async () => {
    const data = await apiCreateGoCardlessMerchant(context.currentClub.clubId);
    setGoCardlessUrl(data.url);
  }, [context.currentClub.clubId]);

  const completeClubSetup = async () => {
    window.location.href = goCardlessUrl;
  };

  const getExistingData = useCallback(async () => {
    if (context.currentClub.clubId) {
      await getEventTypes();
      await getEventFees();
      await getFeeCategories();
      await getMembers();
      await getTeams();
    }
  }, [
    context.currentClub.clubId,
    getEventTypes,
    getEventFees,
    getMembers,
    getFeeCategories,
    getTeams,
  ]);

  useEffect(() => {
    getExistingData();
  }, [getExistingData]);

  useEffect(() => {
    if (currentStep === 6) {
      createGoCardlessMerchant();
    }
  }, [createGoCardlessMerchant, currentStep]);

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={currentStep}
        onGoBack={
          context.currentClub.clubId &&
          (() => {
            if (currentStep === 0) {
              history.push("/");
              dispatch({
                type: "showSelectClub",
              });
            } else {
              setCurrentStep(currentStep - 1);
            }
          })
        }
        onLogout={() => {
          dispatch({
            type: "clearToken",
          });
          dispatch({
            type: "clearSessionVariables",
          });
          history.push("/");
        }}
        showStepTracker={true}
        onStepClick={(i) => setCurrentStep(i)}
        steps={[
          <EditClubDetailsForm
            onSubmit={(clubDetailsPayload) => {
              createOrUpdateClub(clubDetailsPayload);
            }}
            submitting={creatingOrUpdatingClub}
            errorMessage={createClubError}
            initialValues={
              context.currentClub && context.currentClub.address
                ? context.currentClub
                : { address: {} }
            }
          />,
          <EditEventTypesForm
            eventTypes={eventTypes}
            onCreateEventType={(eventTypePayload) => {
              createEventType(eventTypePayload);
            }}
            onSubmit={() => {
              setCurrentStep(currentStep + 1);
              apiUpdateClubSetupProgress(
                context.currentClub.clubId,
                currentStep + 1
              );
            }}
            submitting={creatingEventType}
            errorMessage={createEventTypeError}
            onEditEventType={(eventType) => setEditEventType(eventType)}
            onDeleteEventType={(eventType) => setDeleteEventType(eventType)}
          />,
          <EditFeesForm
            feeCategories={feeCategories}
            onCreateFeeCategory={(feeCategoryPayload) => {
              createFeeCategory(feeCategoryPayload);
            }}
            onSubmit={() => {
              setCurrentStep(currentStep + 1);
              apiUpdateClubSetupProgress(
                context.currentClub.clubId,
                currentStep + 1
              );
            }}
            submitting={creatingFeeCategory}
            errorMessage={createFeeCategoryError}
            onEditFeeCategory={(feeCategory) => setEditFeeCategory(feeCategory)}
            onDeleteFeeCategory={(feeCategory) =>
              setDeleteFeeCategory(feeCategory)
            }
          />,
          <EditEventFeesForm
            onSubmit={() => {
              updateAllEventFees();
            }}
            eventFees={eventFees}
            feeCategories={feeCategories}
            eventTypes={eventTypes}
            onEditEventFee={(eventTypeId, feeCategoryId, value) =>
              updateEventFee(eventTypeId, feeCategoryId, value)
            }
            errorMessage={updatingAllEventFeesError}
            submitting={updatingAllEventFees}
          />,
          <AddInitialPlayersForm
            members={members}
            onShowAddPlayer={() => {
              toggleShowAddPlayer(true);
              storeNewMember({
                feeCategoryId: "",
              });
            }}
            onShowUploadSpreadsheet={() => toggleShowUploadSpreadsheet(true)}
            onSubmit={() => {
              setCurrentStep(currentStep + 1);
              apiUpdateClubSetupProgress(
                context.currentClub.clubId,
                currentStep + 1
              );
            }}
            onUploadSpreadsheet={(file) => uploadSpreadsheet(file)}
            uploadingSpreadsheet={uploadingSpreadsheet}
            unsuccessfulUploads={unsuccessfulUploads}
            onEditMember={(member) =>
              setEditMember({
                ...member,
                feeCategoryId: member.feeCategory && member.feeCategory.id,
              })
            }
            onEditUserEmail={(member) => setEditUserEmail(member)}
            onDeleteMember={(member) => setDeleteMember(member)}
          />,
          <SetupTeamsForm
            teams={teams}
            onSubmit={() => {
              setCurrentStep(currentStep + 1);
              apiUpdateClubSetupProgress(
                context.currentClub.clubId,
                currentStep + 1
              );
            }}
            onShowAddTeam={() => {
              toggleShowAddTeam(true);
              storeNewTeam({ newCaptains: [] });
            }}
            onEditTeam={(team) => {
              setEditTeam(team);
              setUpdateTeamError(null);
            }}
            onDeleteTeam={(team) => setDeleteTeam(team)}
          />,
          <SetupGoCardlessForm
            goCardlessUrl={goCardlessUrl}
            onSubmit={(updatedPayload) => completeClubSetup(updatedPayload)}
          />,
        ]}
      />

      {editEventType && (
        <DialogModal
          title="Edit event type"
          submitText="Update"
          onSubmit={() => updateEventType(editEventType)}
          onClose={() => setEditEventType(null)}
          error
          submitting={updatingEventType}
        >
          <EditEventTypeForm
            payload={editEventType}
            storePayload={(payload) => setEditEventType(payload)}
            errorMessage={updateEventTypeError}
          />
        </DialogModal>
      )}

      {editFeeCategory && (
        <DialogModal
          title="Edit fee category"
          submitText="Update"
          onSubmit={() => updateFeeCategory(editFeeCategory)}
          onClose={() => setEditFeeCategory(null)}
          error
          submitting={updatingFeeCategory}
        >
          <EditFeeCategoryForm
            payload={editFeeCategory}
            storePayload={(payload) => setEditFeeCategory(payload)}
            errorMessage={updateFeeCategoryError}
          />
        </DialogModal>
      )}

      {showDeleteFeeCategory && (
        <YesNoDialogModal
          title="Are you sure you want to delete this fee category?"
          onClose={() => setDeleteFeeCategory(false)}
          onSubmit={() => deleteFeeCategory()}
          submitting={deletingFeeCategory}
          error={deleteFeeCategoryError}
        />
      )}

      {/* {editEventFee && (
        <DialogModal submitText="Update" onSubmit={() => createOrUpdateEventFee(editEventFee)} onClose={() => setEditEventFee(null)} error submitting={creatingOrUpdatingEventFee}>
          <EditEventFeeForm payload={editEventFee} storePayload={(payload) => setEditEventFee(payload)} errorMessage={createOrUpdateEventFeeError} />
        </DialogModal>
      )} */}

      {showDeleteEventType && (
        <YesNoDialogModal
          title="Are you sure you want to delete this event type?"
          onClose={() => setDeleteEventType(false)}
          onSubmit={() => deleteEventType()}
          submitting={deletingEventType}
          error={deleteEventTypeError}
        />
      )}

      {showAddMember && (
        <DialogModal
          submitText="Add"
          onSubmit={() => createMember(newMember)}
          onClose={() => toggleShowAddPlayer(false)}
          error
          submitting={creatingMember}
        >
          <AddMemberForm
            payload={newMember}
            storePayload={(payload) => storeNewMember(payload)}
            // successMessage={createMemberSuccessMessage}
            errorMessage={createMemberError}
            feeCategories={feeCategories}
          />
        </DialogModal>
      )}

      {showMemberAdded && (
        <DialogModal
          title="New member successfully added"
          submitText="Add another member"
          onSubmit={() => {
            toggleShowAddPlayer(true);
            toggleShowMemberAdded(false);
          }}
          onClose={() => toggleShowMemberAdded(false)}
        ></DialogModal>
      )}

      {showUploadSpreadsheet && (
        <DialogModal onClose={() => toggleShowUploadSpreadsheet(false)}>
          <UploadSpreadsheetForm
            clubId={context.currentClub.clubId}
            getMembers={() => getMembers()}
            onClose={() => toggleShowUploadSpreadsheet(false)}
          />
        </DialogModal>
      )}

      {editMember && (
        <DialogModal
          submitText="Update"
          onSubmit={() => updateMember(editMember)}
          onClose={() => setEditMember(null)}
          submitting={updatingMember}
          error
        >
          <EditMemberForm
            payload={editMember}
            storePayload={(payload) => setEditMember(payload)}
            errorMessage={updateMemberError}
            feeCategories={feeCategories}
          />
        </DialogModal>
      )}

      {editUserEmail && (
        <DialogModal
          submitText="Done"
          onSubmit={() => setEditUserEmail(null)}
          onClose={() => setEditUserEmail(null)}
          submitting={updatingUserEmail}
          error
        >
          <EditUserEmailForm
            payload={editUserEmail}
            storePayload={(payload) => setEditUserEmail(payload)}
            errorMessage={updateUserEmailError}
            onResendInvitationEmail={(payload) =>
              resendInvitationEmail(payload)
            }
            resendingInvitationEmail={resendingInvitationEmail}
          />
        </DialogModal>
      )}

      {showDeleteMember && (
        <YesNoDialogModal
          title="Are you sure you want to delete this member?"
          onClose={() => setDeleteMember(false)}
          onSubmit={() => deleteMember()}
          submitting={deletingMember}
          error={deleteMemberError}
        />
      )}

      {uploadSuccessMessage && (
        <DialogModal
          onClose={() => setUploadSuccessMessage(null)}
          cancelText="Close"
        >
          <div>{uploadSuccessMessage}</div>
          <div style={{ marginTop: 10 }}>
            {unsuccessfulUploads.map((unsuccessfulUpload) => {
              return (
                <div className={classes.uploadPlayer}>
                  <span>
                    {unsuccessfulUpload.firstName} {unsuccessfulUpload.lastName}{" "}
                  </span>
                  <span className={classes.failedUploadReason}>
                    ({unsuccessfulUpload.reason})
                  </span>
                </div>
              );
            })}
          </div>
        </DialogModal>
      )}

      {showAddTeam && (
        <DialogModal
          submitText="Add"
          onSubmit={() => createTeam(newTeam)}
          onClose={() => toggleShowAddTeam(false)}
          error
          submitting={creatingTeam}
        >
          <AddTeamForm
            payload={newTeam}
            storePayload={(payload) => storeNewTeam(payload)}
            errorMessage={createTeamError}
            members={members}
          />
        </DialogModal>
      )}

      {editTeam && (
        <DialogModal
          submitText="Update"
          onSubmit={() => updateTeam(editTeam)}
          onClose={() => setEditTeam(null)}
          error
          submitting={updatingTeam}
        >
          <EditTeamForm
            payload={editTeam}
            storePayload={(payload) => setEditTeam(payload)}
            errorMessage={updateTeamError}
            members={members}
            captains={editTeam.captains}
            addingCaptain={addingCaptain}
            onAddCaptain={(playerId) => addCaptain(playerId)}
            onDeleteCaptain={(captain) => setDeleteCaptain(captain)}
          />
        </DialogModal>
      )}

      {showDeleteTeam && (
        <YesNoDialogModal
          title="Are you sure you want to delete this team?"
          onClose={() => setDeleteTeam(false)}
          onSubmit={() => deleteTeam()}
          submitting={deletingTeam}
          error={deleteTeamError}
        />
      )}

      {showDeleteCaptain && (
        <YesNoDialogModal
          title="Are you sure you want to delete this captain?"
          onClose={() => setDeleteCaptain(false)}
          onSubmit={() => deleteCaptain()}
          submitting={deletingCaptain}
          error={deleteCaptainError}
        />
      )}
    </PageWrapper>
  );
};

export default withStyles(styles)(withState(CreateClub));
