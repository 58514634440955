import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

function EditUserEmailForm({
  classes,
  payload,
  onSubmit,
  errorMessage,
  submitting,
  onResendInvitationEmail,
  resendingInvitationEmail,
}) {
  return (
    <div className={classes.section}>
      <Button
        title="RESEND INVITATION EMAIL"
        onClick={() => onResendInvitationEmail(payload)}
        submitting={resendingInvitationEmail}
        style={{ minWidth: "221.328px", marginBottom: "20px" }}
      />
      <ErrorMessage message={errorMessage} />
      {onSubmit && (
        <Button
          title="Done"
          onClick={() => {
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(EditUserEmailForm);
