import React from "react";
import { withStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  button: {
    backgroundColor: "#3CC87B",
    borderRadius: "3px",
    padding: "10px 15px",
    color: "#fff",
    cursor: "pointer",
    display: "inline-block",
    minWidth: 100,
    textAlign: "center",
    position: "relative"
  },
  selectFile: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer"
  }
});

function SelectFileButton({ classes, style, title, onClick, submitting, disabled, onChange }) {
  return (
    <div className={classes.button} style={style || { opacity: disabled ? 0.4 : 1 }} onClick={() => !disabled && onClick && onClick()}>
      {!submitting && title}
      {submitting && <CircularProgress color="#fff" size={14} />}
      {!submitting && <input type="file" className={classes.selectFile} onChange={event => onChange(event.target.files[0])} />}
    </div>
  );
}

export default withStyles(styles)(SelectFileButton);
