import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { uploadSpreadsheet as apiUploadSpreadsheet } from "../../../services/api";

import FormHeader from "../../components/formHeader/FormHeader";
import FormDescription from "../../components/formDescription/FormDescription";
import SelectFileButton from "../../components/selectFileButton/SelectFileButton";

import UploadExample from "./SlateTemplate.xls";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  uploadPlayer: {
    margin: "5px 0",
  },
  failedUploadReason: {
    fontStyle: "italic",
  },
});

function UploadSpreadsheetForm({
  classes,
  clubId,
  getMembers,
  onClose,
  setUnsuccessfulUploads,
  setUploadSuccessMessage,
}) {
  const [uploadingSpreadsheet, setUploadingSpreadsheet] = React.useState(false);

  const uploadSpreadsheet = async (file) => {
    setUploadingSpreadsheet(true);
    setUploadSuccessMessage("");
    const data = await apiUploadSpreadsheet(clubId, file);
    setUploadingSpreadsheet(false);
    getMembers();
    if (data) {
      setUploadSuccessMessage(
        `Upload completed. ${data.successfulUploadCount} players uploaded successfully.`
      );

      if (data.unsuccessfulUploads) {
        setUnsuccessfulUploads(data.unsuccessfulUploads);
      }
    }
    onClose();
  };

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Add players via a Spreadsheet" />
        <FormDescription>
          <div>
            Simply input your players' details to the spreadsheet we provide and
            upload below. You can download the template you have to use{" "}
            <a href={UploadExample}>here</a>. It will be in your downloads
            folder.
          </div>
        </FormDescription>
      </div>

      <SelectFileButton
        title="Select file"
        onChange={(file) => {
          uploadSpreadsheet(file);
        }}
        submitting={uploadingSpreadsheet}
      />
    </div>
  );
}

export default withStyles(styles)(UploadSpreadsheetForm);
