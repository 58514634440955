import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getEventTypes as apiGetEventTypes,
  getFeeCategories as apiGetFeeCategories,
  getEventFees as apiGetEventFees,
  // createEventFee as apiCreateEventFee,
  // updateEventFee as apiUpdateEventFee,
  updateAllEventFees as apiUpdateAllEventFees,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
// import DialogModal from "../../components/dialogModal/DialogModal";
import Section from "../../components/section/Section";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

import EventFeesTable from "../../tables/eventFeesTable/EventFeesTable";
// import EditEventFeeForm from "../../forms/editEventFeeForm/EditEventFeeForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  eventFeeTable: {
    marginBottom: 30,
  },
  formActions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
});

function EventFeesPage({ history, classes, dispatch, context }) {
  const [gettingEventFees, setGettingEventFees] = React.useState(false);
  const [eventFees, setEventFees] = React.useState([]);
  const [gettingEventTypes, setGettingEventTypes] = React.useState(false);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [gettingFeeCategories, setGettingFeeCategories] = React.useState(false);
  const [feeCategories, setFeeCategories] = React.useState([]);
  // const [editEventFee, setEditEventFee] = React.useState(null);
  // const [creatingOrUpdatingEventFee, setCreatingOrUpdatingEventFee] = React.useState(false);
  // const [createOrUpdateEventFeeError, setCreateOrUpdateEventFeeError] = React.useState("");
  const [updatingAllEventFees, setUpdatingAllEventFees] = React.useState(false);
  const [
    updatingAllEventFeesError,
    setUpdatingAllEventFeesError,
  ] = React.useState("");

  const renderEventFees = () => {
    return (
      <EventFeesTable
        hideSearch={true}
        eventTypes={eventTypes}
        feeCategories={feeCategories}
        eventFees={eventFees}
        onEditEventFee={(eventTypeId, feeCategoryId, value) =>
          updateEventFee(eventTypeId, feeCategoryId, value)
        }
      />
    );
  };

  const getEventTypes = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingEventTypes(true);
      const data = await apiGetEventTypes(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setEventTypes(data);
      }
      setGettingEventTypes(false);
    } catch (e) {
      console.log(e);
      setGettingEventTypes(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const getFeeCategories = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingFeeCategories(true);
      const data = await apiGetFeeCategories(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setFeeCategories(data);
      }
      setGettingFeeCategories(false);
    } catch (e) {
      console.log(e);
      setGettingFeeCategories(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const getEventFees = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      setGettingEventFees(true);
      const data = await apiGetEventFees(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setEventFees(data);
      }
      setGettingEventFees(false);
    } catch (e) {
      console.log(e);
      setGettingEventFees(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const getEventFee = (eventTypeId, feeCategoryId) => {
    return eventFees.find((eventFee) => {
      return (
        eventFee.eventType &&
        eventFee.eventType.id === eventTypeId &&
        eventFee.feeCategory &&
        eventFee.feeCategory.id === feeCategoryId
      );
    });
  };

  const updateEventFee = (eventTypeId, feeCategoryId, value) => {
    let currentEventFees = eventFees.map((eventFee) => eventFee);
    let currentEventFee = currentEventFees.find((eventFee) => {
      return (
        eventFee.eventType &&
        eventFee.eventType.id === eventTypeId &&
        eventFee.feeCategory &&
        eventFee.feeCategory.id === feeCategoryId
      );
    });

    if (!currentEventFee) {
      currentEventFees.push({
        eventType: {
          id: eventTypeId,
        },
        feeCategory: {
          id: feeCategoryId,
        },
        defaultFee: value,
      });
    } else {
      currentEventFee.defaultFee = value;
    }

    setEventFees(currentEventFees);
  };

  // const createOrUpdateEventFee = async (payload) => {
  //   if (payload.defaultFee === "" || payload.defaultFee === null || payload.defaultFee === undefined || isNaN(payload.defaultFee)) {
  //     return setCreateOrUpdateEventFeeError("Please enter a valid number");
  //   }

  //   setCreateOrUpdateEventFeeError("");

  //   try {
  //     setCreatingOrUpdatingEventFee(true);

  //     const existingEventFee = getEventFee(payload.eventTypeId, payload.feeCategoryId);

  //     if (existingEventFee) {
  //       await apiUpdateEventFee(context.currentClub.clubId, existingEventFee.id, payload);
  //     } else {
  //       await apiCreateEventFee(context.currentClub.clubId, payload);
  //     }

  //     setEditEventFee(null);
  //     setCreatingOrUpdatingEventFee(false);
  //     getEventFees();
  //   } catch (e) {
  //     setCreatingOrUpdatingEventFee(false);
  //     setCreateOrUpdateEventFeeError(e.message);
  //   }
  // };

  const updateAllEventFees = async () => {
    for (let i = 0; i < eventTypes.length; i++) {
      for (let j = 0; j < feeCategories.length; j++) {
        const existingEventFee = getEventFee(
          eventTypes[i].id,
          feeCategories[j].id
        );

        if (
          !existingEventFee ||
          existingEventFee.defaultFee === null ||
          existingEventFee.defaultFee === undefined ||
          existingEventFee.defaultFee === ""
        ) {
          setUpdatingAllEventFeesError(
            "Please assign values for all event fees"
          );
          return false;
        }
      }
    }

    try {
      setUpdatingAllEventFees(true);

      await apiUpdateAllEventFees(
        context.currentClub.clubId,
        eventFees.map((eventFee) => {
          return {
            id: eventFee.id,
            feeCategoryId: eventFee.feeCategory.id,
            eventTypeId: eventFee.eventType.id,
            defaultFee: eventFee.defaultFee,
          };
        })
      );
      await getEventFees();

      setUpdatingAllEventFees(false);
      getEventFees();
    } catch (e) {
      setUpdatingAllEventFees(false);
      setUpdatingAllEventFeesError(e.message);
    }
  };

  useEffect(() => {
    getEventTypes();
    getFeeCategories();
    getEventFees();
  }, [getEventTypes, getFeeCategories, getEventFees]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Event fees"
      showLoading={
        gettingEventFees || gettingEventTypes || gettingFeeCategories
      }
    >
      <Section>
        {renderEventFees()}
        <div className={classes.formActions}>
          {updatingAllEventFeesError && (
            <ErrorMessage message={updatingAllEventFeesError} />
          )}
          <Button
            title="Save"
            onClick={() => {
              updateAllEventFees();
            }}
            submitting={updatingAllEventFees}
          />
        </div>
      </Section>

      {/* {editEventFee && (
        <DialogModal submitText="Update" onSubmit={() => createOrUpdateEventFee(editEventFee)} onClose={() => setEditEventFee(null)} error submitting={creatingOrUpdatingEventFee}>
          <EditEventFeeForm payload={editEventFee} storePayload={(payload) => setEditEventFee(payload)} errorMessage={createOrUpdateEventFeeError} />
        </DialogModal>
      )} */}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(EventFeesPage));
