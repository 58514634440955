import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getMembers as apiGetMembers,
  createMember as apiCreateMember,
  deleteMember as apiDeleteMember,
  updateMember as apiUpdateMember,
  updateMemberFeeCategory as apiUpdateMemberFeeCategory,
  updateUserEmail as apiUpdateUserEmail,
  getFeeCategories as apiGetFeeCategories,
  resendInvitationEmail as apiResendInvitationEmail,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import Section from "../../components/section/Section";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";

import MembershipTable from "../../tables/membershipTable/MembershipTable";
import AddMemberForm from "../../forms/addMemberForm/AddMemberForm";
import UploadSpreadsheetForm from "../../forms/uploadSpreadsheetForm/UploadSpreadsheetForm";
import EditMemberForm from "../../forms/editMemberForm/EditMemberForm";
import EditUserEmailForm from "../../forms/editUserEmailForm/EditUserEmailForm";

const isInvalidEmail = (email) => !email;
const isInvalidConfirmEmail = (email, confirmEmail) => email !== confirmEmail;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function MembersPage({ history, dispatch, context, classes }) {
  const [showAddMember, toggleShowAddMember] = React.useState(false);
  const [newMember, storeNewMember] = React.useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    confirmEmailAddress: "",
  });
  const [showUploadSpreadsheet, toggleShowUploadSpreadsheet] = React.useState(
    false
  );

  // const [gettingMembers, setGettingMembers] = React.useState(true);
  const [creatingMember, setCreatingMember] = React.useState(false);
  const [createMemberError, setCreateMemberError] = React.useState(true);
  const [
    createMemberSuccessMessage,
    setCreateMemberSuccessMessage,
  ] = React.useState(true);
  const [editMember, setEditMember] = React.useState(null);
  const [updatingMember, setUpdatingMember] = React.useState(false);
  const [updateMemberError, setUpdateMemberError] = React.useState(true);
  const [editUserEmail, setEditUserEmail] = React.useState(null);
  const [updatingUserEmail, setUpdatingUserEmail] = React.useState(false);
  const [updateUserEmailError, setUpdateUserEmailError] = React.useState(true);
  const [
    resendingInvitationEmail,
    setResendingInvitationEmail,
  ] = React.useState(false);
  const [showDeleteMember, setDeleteMember] = React.useState(null);
  const [deletingMember, setDeletingMember] = React.useState(false);
  const [deleteMemberError, setDeleteMemberError] = React.useState(true);
  const [members, setMembers] = React.useState([]);
  // const [gettingFeeCategories, setGettingFeeCategories] = React.useState(false);
  const [feeCategories, setFeeCategories] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState("");
  const [reportType, setReportType] = React.useState("playerStatusReport");
  const [unsuccessfulUploads, setUnsuccessfulUploads] = React.useState([]);
  const [uploadSuccessMessage, setUploadSuccessMessage] = React.useState(null);
  const [showMemberAdded, toggleShowMemberAdded] = React.useState(false);

  const getMembers = useCallback(async () => {
    try {
      if (!context.currentClub.clubId) {
        return false;
      }

      // setGettingMembers(true);
      const data = await apiGetMembers(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setMembers(data);
      }
      // setGettingMembers(false);
    } catch (e) {
      console.log(e);
      // setGettingMembers(false);
    }
  }, [context.currentClub.clubId, context.currentPlayer.id]);

  const createMember = async (payload) => {
    if (
      !payload.firstName ||
      !payload.lastName ||
      !payload.emailAddress ||
      !payload.feeCategoryId
    ) {
      setCreateMemberError("Please complete all fields");
      return false;
    }

    if (payload.emailAddress !== payload.confirmEmailAddress) {
      setCreateMemberError("Email addresses do not match");
      return false;
    }

    setCreateMemberError("");

    try {
      setCreatingMember(true);
      const memberId = await apiCreateMember(context.currentClub.clubId, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        emailAddress: payload.emailAddress.toLowerCase(),
      });
      await apiUpdateMemberFeeCategory(
        context.currentClub.clubId,
        memberId,
        payload.feeCategoryId
      );

      setCreatingMember(false);
      storeNewMember({
        firstName: "",
        lastName: "",
        emailAddress: "",
        confirmEmailAddress: "",
      });
      setCreateMemberSuccessMessage("New member successfully added");
      toggleShowAddMember(false);
      toggleShowMemberAdded(true);
      getMembers();
    } catch (e) {
      setCreatingMember(false);
      setCreateMemberError(e.message);
    }
  };

  const updateMember = async (payload) => {
    if (!payload.firstName || !payload.lastName || !payload.feeCategoryId) {
      setUpdateMemberError("Please complete all fields");
      return false;
    }

    setUpdateMemberError("");

    try {
      setUpdatingMember(true);
      await apiUpdateMember(context.currentClub.clubId, payload);
      await apiUpdateMemberFeeCategory(
        context.currentClub.clubId,
        payload.memberId,
        payload.feeCategoryId
      );
      await updateUserEmail(payload);
      setEditMember(null);
      setUpdatingMember(null);
      getMembers();
    } catch (e) {
      setUpdatingMember(null);
      setUpdateMemberError(e.message);
    }
  };

  const updateUserEmail = async (payload) => {
    if (isInvalidEmail(payload.emailAddress)) {
      return setUpdateUserEmailError("Please enter an email address");
    }

    if (
      isInvalidConfirmEmail(payload.emailAddress, payload.confirmEmailAddress)
    ) {
      return setUpdateUserEmailError("Email address do not match");
    }

    try {
      setUpdateUserEmailError("");
      setUpdatingUserEmail(true);
      await apiUpdateUserEmail(context.currentClub.clubId, payload);
      setEditUserEmail(null);
      setUpdatingUserEmail(null);
      getMembers();
    } catch (e) {
      setUpdatingMember(null);
      setUpdateUserEmailError(e.message);
    }
  };

  const resendInvitationEmail = async (payload) => {
    try {
      setUpdateUserEmailError("");
      setResendingInvitationEmail(true);
      await apiResendInvitationEmail(context.currentClub.clubId, payload);
      setEditUserEmail(null);
      setResendingInvitationEmail(null);
      getMembers();
    } catch (e) {
      setResendingInvitationEmail(null);
    }
  };

  const deleteMember = async () => {
    try {
      setDeletingMember(true);
      await apiDeleteMember(
        context.currentClub.clubId,
        showDeleteMember.memberId
      );
      setDeleteMember(null);
      setDeletingMember(null);
      getMembers();
    } catch (e) {
      setDeletingMember(null);
      setDeleteMemberError(e.message);
    }
  };

  const getFeeCategories = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingFeeCategories(true);
      const data = await apiGetFeeCategories(context.currentClub.clubId);
      if (data && !data.reason) {
        setFeeCategories(data);
      }
      // setGettingFeeCategories(false);
    } catch (e) {
      console.log(e);
      // setGettingFeeCategories(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  useEffect(() => {
    getMembers();
    getFeeCategories();
  }, [getMembers, getFeeCategories]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Members"
    >
      <Section>
        <DropdownSelect
          label={"Report type"}
          value={reportType}
          items={[
            { label: "Player status report", value: "playerStatusReport" },
            {
              label: "Player personal information report",
              value: "playerPersonalInformationReport",
            },
          ]}
          onSelect={(value) => {
            setReportType(null);
            setTimeout(() => {
              setReportType(value);
              setFilterStatus(null);
            }, 50);
          }}
        />
        <MembershipTable
          reportType={reportType}
          members={members
            .filter((member) => {
              if (reportType === "playerStatusReport") {
                if (filterStatus === true) {
                  return member.hasAcceptedInvite;
                } else if (filterStatus === false) {
                  return !member.hasAcceptedInvite;
                }
              } else if (reportType === "playerPersonalInformationReport") {
                if (filterStatus === true) {
                  return member.hasConsented;
                } else if (filterStatus === false) {
                  return !member.hasConsented;
                }
              }
              return true;
            })
            .sort((a, b) =>
              a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
            )}
          addButtonText="Add member"
          onAddButtonClick={() => {
            toggleShowAddMember(true);
            setCreateMemberError("");
            storeNewMember({
              feeCategoryId: "",
            });
          }}
          onEditMember={(member) =>
            setEditMember({
              ...member,
              feeCategoryId: member.feeCategory && member.feeCategory.id,
            })
          }
          onEditUserEmail={(member) => setEditUserEmail(member)}
          onDeleteMember={(member) => setDeleteMember(member)}
          additionalButtons={[
            {
              title: "Add players via a Spreadsheet",
              onPress: () => toggleShowUploadSpreadsheet(true),
            },
          ]}
          filterSelects={[
            {
              items: [
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ],
              label:
                reportType === "playerStatusReport"
                  ? "Filter joined status"
                  : "Filter consented status",
              value: filterStatus,
              onSelect: (value) => {
                setFilterStatus(value);
              },
              placeholder: "Any status",
            },
          ]}
          showDownloadButton={true}
        />
      </Section>

      {showAddMember && (
        <DialogModal
          submitText="Add"
          onSubmit={() => createMember(newMember)}
          onClose={() => toggleShowAddMember(false)}
          error
          submitting={creatingMember}
        >
          <AddMemberForm
            payload={newMember}
            storePayload={(payload) => storeNewMember(payload)}
            errorMessage={createMemberError}
            successMessage={createMemberSuccessMessage}
            feeCategories={feeCategories}
          />
        </DialogModal>
      )}

      {editMember && (
        <DialogModal
          submitText="Update"
          onSubmit={() => updateMember(editMember)}
          onClose={() => setEditMember(null)}
          submitting={updatingMember}
          error
        >
          <EditMemberForm
            payload={editMember}
            storePayload={(payload) => setEditMember(payload)}
            errorMessage={updateMemberError}
            feeCategories={feeCategories}
          />
        </DialogModal>
      )}

      {editUserEmail && (
        <DialogModal
          submitText="Done"
          onSubmit={() => setEditUserEmail(null)}
          onClose={() => setEditUserEmail(null)}
          submitting={updatingUserEmail}
          error
        >
          <EditUserEmailForm
            payload={editUserEmail}
            storePayload={(payload) => setEditUserEmail(payload)}
            errorMessage={updateUserEmailError}
            onResendInvitationEmail={(payload) =>
              resendInvitationEmail(payload)
            }
            resendingInvitationEmail={resendingInvitationEmail}
          />
        </DialogModal>
      )}

      {showUploadSpreadsheet && (
        <DialogModal onClose={() => toggleShowUploadSpreadsheet(false)}>
          <UploadSpreadsheetForm
            clubId={context.currentClub.clubId}
            getMembers={() => getMembers()}
            onClose={() => toggleShowUploadSpreadsheet(false)}
            setUnsuccessfulUploads={(unsuccessfulUploads) =>
              setUnsuccessfulUploads(unsuccessfulUploads)
            }
            setUploadSuccessMessage={(uploadMessage) =>
              setUploadSuccessMessage(uploadMessage)
            }
          />
        </DialogModal>
      )}

      {uploadSuccessMessage && (
        <DialogModal
          onClose={() => setUploadSuccessMessage(null)}
          cancelText="Close"
        >
          <div>{uploadSuccessMessage}</div>
          <div style={{ marginTop: 10 }}>
            {unsuccessfulUploads.map((unsuccessfulUpload) => {
              return (
                <div className={classes.uploadPlayer}>
                  <span>
                    {unsuccessfulUpload.firstName} {unsuccessfulUpload.lastName}{" "}
                  </span>
                  <span className={classes.failedUploadReason}>
                    ({unsuccessfulUpload.reason})
                  </span>
                </div>
              );
            })}
          </div>
        </DialogModal>
      )}

      {showDeleteMember && (
        <YesNoDialogModal
          title="Are you sure you want to delete this member?"
          onClose={() => setDeleteMember(false)}
          onSubmit={() => deleteMember()}
          submitting={deletingMember}
          error={deleteMemberError}
        />
      )}

      {showMemberAdded && (
        <DialogModal
          title="New member successfully added"
          submitText="Add another member"
          onSubmit={() => {
            toggleShowAddMember(true);
            toggleShowMemberAdded(false);
          }}
          onClose={() => toggleShowMemberAdded(false)}
        ></DialogModal>
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(MembersPage));
