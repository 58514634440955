import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";
import SuccessMessage from "../../components/successMessage/SuccessMessage";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const isInvalidEmail = (email) => !email;
const isInvalidPassword = (password) => !password || password.length < 6;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  twoFields: {
    display: "flex",
    width: "100%",
  },
  authLink: {
    margin: "10px 0",
  },
});

function ResetPasswordForm({ classes, onSubmit, message, error, submitting, submitText }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState({});

  console.log(submitText);
  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Reset password" />
      </div>
      <SingleLineTextInput
        label="EMAIL ADDRESS*"
        value={payload.emailAddress}
        onChange={(value) => storePayload({ ...payload, emailAddress: value })}
        showInvalidInput={submitAttempted && isInvalidEmail(payload.emailAddress)}
        style={{ width: "100%" }}
        type="text"
      />
      <SingleLineTextInput
        label="NEW PASSWORD* (min 8 characters, 1 upper case, 1 number)"
        value={payload.password}
        onChange={(value) => storePayload({ ...payload, password: value })}
        showInvalidInput={submitAttempted && isInvalidPassword(payload.password)}
        style={{ width: "100%" }}
        type="password"
      />
      <ErrorMessage message={error} />
      <SuccessMessage message={message} />
      <Button
        title={submitText || "Submit"}
        onClick={() => {
          setSubmitAttempted(true);
          onSubmit(payload, () => {
            setSubmitAttempted(false);
            storePayload({
              emailAddress: "",
              password: "",
            });
          });
        }}
        submitting={submitting}
      />
      <div className={classes.authLinks}>
        <Link to="/login">
          <div className={classes.authLink}>Login to your account</div>
        </Link>
      </div>
    </div>
  );
}

export default withStyles(styles)(ResetPasswordForm);
