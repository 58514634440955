import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import FormDescription from "../../components/formDescription/FormDescription";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import SuccessMessage from "../../components/successMessage/SuccessMessage";

const isInvalidName = (name) => !name;
const isInvalidEmail = (email) => !email;
const isInvalidConfirmEmail = (email, confirmEmail) => email !== confirmEmail;

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
});

function AddMemberForm({
  classes,
  payload,
  storePayload,
  onSubmit,
  errorMessage,
  successMessage,
  feeCategories,
}) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title={payload.playerId ? "Edit member" : "Add member"} />
        <FormDescription>
          <div>Enter your name and email address</div>
        </FormDescription>
      </div>
      <SingleLineTextInput
        label="FIRST NAME*"
        value={payload.firstName}
        onChange={(value) => storePayload({ ...payload, firstName: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.firstName)}
      />
      <SingleLineTextInput
        label="LAST NAME*"
        value={payload.lastName}
        onChange={(value) => storePayload({ ...payload, lastName: value })}
        showInvalidInput={submitAttempted && isInvalidName(payload.lastName)}
      />
      <SingleLineTextInput
        label="EMAIL ADDRESS*"
        value={payload.emailAddress}
        onChange={(value) => storePayload({ ...payload, emailAddress: value })}
        showInvalidInput={
          submitAttempted && isInvalidEmail(payload.emailAddress)
        }
        type="email"
      />
      <SingleLineTextInput
        label="CONFIRM EMAIL ADDRESS*"
        value={payload.confirmEmailAddress}
        onChange={(value) =>
          storePayload({ ...payload, confirmEmailAddress: value })
        }
        showInvalidInput={
          submitAttempted &&
          isInvalidConfirmEmail(
            payload.emailAddress,
            payload.confirmEmailAddress
          )
        }
        type="email"
      />
      <DropdownSelect
        label="FEE CATEGORY*"
        items={feeCategories.map((feeCategory) => {
          return { value: feeCategory.id, label: feeCategory.name };
        })}
        onSelect={(itemId) =>
          storePayload({ ...payload, feeCategoryId: itemId })
        }
        value={payload.feeCategoryId}
      />
      <ErrorMessage message={errorMessage} />
      <SuccessMessage message={successMessage} />
      <div className={classes.modalButtons}>
        <div />
        <div>
          {onSubmit && (
            <Button
              title="Next"
              onClick={() => {
                setSubmitAttempted(true);
                onSubmit(payload);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(AddMemberForm);
