import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getTeams as apiGetTeams,
  getMembers as apiGetMembers,
  createTeam as apiCreateTeam,
  deleteTeam as apiDeleteTeam,
  deleteCaptain as apiDeleteCaptain,
  updateTeam as apiUpdateTeam,
  addTeamCaptain as apiAddCaptain,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import DialogModal from "../../components/dialogModal/DialogModal";
import YesNoDialogModal from "../../components/yesNoDialogModal/YesNoDialogModal";
import Section from "../../components/section/Section";

import TeamsTable from "../../tables/teamsTable/TeamsTable";
import AddTeamForm from "../../forms/addTeamForm/AddTeamForm";
import EditTeamForm from "../../forms/editTeamForm/EditTeamForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function TeamsPage({ history, classes, dispatch, context }) {
  const [showAddTeam, toggleShowAddTeam] = React.useState(false);
  const [newTeam, storeNewTeam] = React.useState({
    newCaptains: [],
  });
  // const [gettingTeams, setGettingTeams] = React.useState(false);
  const [teams, setTeams] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [creatingTeam, setCreatingTeam] = React.useState(false);
  const [createTeamError, setCreateTeamError] = React.useState(true);
  const [editTeam, setEditTeam] = React.useState(null);
  const [updatingTeam, setUpdatingTeam] = React.useState(false);
  const [updateTeamError, setUpdateTeamError] = React.useState(true);
  const [showDeleteTeam, setDeleteTeam] = React.useState(null);
  const [deletingTeam, setDeletingTeam] = React.useState(false);
  const [showDeleteCaptain, setDeleteCaptain] = React.useState(null);
  const [addingCaptain, setAddingCaptain] = React.useState(false);
  const [deletingCaptain, setDeletingCaptain] = React.useState(false);
  const [deleteTeamError, setDeleteTeamError] = React.useState("");
  const [deleteCaptainError, setDeleteCaptainError] = React.useState("");

  const getTeams = useCallback(async () => {
    if (
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingTeams(true);
      const data = await apiGetTeams(
        context.currentClub.clubId,
        context.currentPlayer.id
      );
      if (data && !data.reason) {
        setTeams(data);
      }
      // setGettingTeams(false);
    } catch (e) {
      console.log(e);
      // setGettingTeams(false);
    }
  }, [context.currentClub, context.currentPlayer]);

  const getMembers = useCallback(async () => {
    try {
      if (!context.currentClub.clubId) {
        return false;
      }

      const data = await apiGetMembers(context.currentClub.clubId);
      if (data) {
        setMembers(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub.clubId]);

  const createTeam = async (payload) => {
    if (!payload.name) {
      return setCreateTeamError("Please enter a team name");
    }

    setCreateTeamError("");

    try {
      setCreatingTeam(true);
      const data = await apiCreateTeam(context.currentClub.clubId, {
        name: payload.name,
        playerId: context.currentPlayer.id,
      });

      payload.newCaptains.forEach(async (newCaptain) => {
        await apiAddCaptain(
          context.currentClub.clubId,
          data,
          newCaptain.playerId
        );
      });

      setCreatingTeam(false);
      toggleShowAddTeam(false);
      getTeams();
    } catch (e) {
      setCreatingTeam(false);
      setCreateTeamError(e.message);
    }
  };

  const updateTeam = async (payload) => {
    if (!payload.name) {
      return setUpdateTeamError("Please enter a team name");
    }

    setUpdateTeamError("");

    try {
      setUpdatingTeam(true);
      await apiUpdateTeam(context.currentClub.clubId, payload.id, payload);
      setEditTeam(null);
      setUpdatingTeam(false);
      getTeams();
    } catch (e) {
      setUpdatingTeam(false);
      setUpdateTeamError(e.message);
    }
  };

  const deleteTeam = async () => {
    try {
      setDeletingTeam(true);
      await apiDeleteTeam(
        context.currentClub.clubId,
        showDeleteTeam.id,
        context.currentPlayer.id
      );
      setDeletingTeam(false);
      setDeleteTeam(null);
      getTeams();
    } catch (e) {
      setDeletingTeam(false);
      setDeleteTeamError(e.message);
    }
  };

  const addCaptain = async (captainId) => {
    try {
      setAddingCaptain(true);
      await apiAddCaptain(context.currentClub.clubId, editTeam.id, captainId);
      getTeams();
      setAddingCaptain(false);
      setEditTeam(null);
      getTeams();
    } catch (e) {
      setAddingCaptain(false);
      setUpdateTeamError(e.message);
    }
  };

  const deleteCaptain = async () => {
    try {
      setDeletingCaptain(true);
      await apiDeleteCaptain(
        context.currentClub.clubId,
        editTeam.id,
        showDeleteCaptain.guid
      );
      getTeams();
      setDeletingCaptain(false);
      setDeleteCaptain(null);
      setEditTeam(null);
      getTeams();
    } catch (e) {
      setDeletingCaptain(false);
      setDeleteCaptainError(e.message);
    }
  };

  useEffect(() => {
    getTeams();
    getMembers();
  }, [getTeams, getMembers]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Teams"
    >
      <Section>
        <TeamsTable
          teams={teams}
          onAddButtonClick={() => {
            toggleShowAddTeam(true);
            storeNewTeam({
              newCaptains: [],
            });
            setCreateTeamError(null);
          }}
          onEditTeam={(team) => {
            setEditTeam(team);
            setUpdateTeamError(null);
          }}
          onDeleteTeam={(team) => setDeleteTeam(team)}
          // onRowClick={(id) => history.push(`/teams/${id}`)}
        />
      </Section>

      {showAddTeam && (
        <DialogModal
          submitText="Add"
          onSubmit={() => createTeam(newTeam)}
          onClose={() => toggleShowAddTeam(false)}
          error
          submitting={creatingTeam}
        >
          <AddTeamForm
            payload={newTeam}
            storePayload={(payload) => storeNewTeam(payload)}
            errorMessage={createTeamError}
            members={members}
          />
        </DialogModal>
      )}

      {editTeam && (
        <DialogModal
          submitText="Update"
          onSubmit={() => updateTeam(editTeam)}
          onClose={() => setEditTeam(null)}
          error
          submitting={updatingTeam}
        >
          <EditTeamForm
            payload={editTeam}
            storePayload={(payload) => setEditTeam(payload)}
            errorMessage={updateTeamError}
            members={members}
            captains={editTeam.captains}
            addingCaptain={addingCaptain}
            onAddCaptain={(playerId) => addCaptain(playerId)}
            onDeleteCaptain={(captain) => setDeleteCaptain(captain)}
          />
        </DialogModal>
      )}

      {showDeleteTeam && (
        <YesNoDialogModal
          title="Are you sure you want to delete this team?"
          onClose={() => setDeleteTeam(false)}
          onSubmit={() => deleteTeam()}
          submitting={deletingTeam}
          error={deleteTeamError}
        />
      )}

      {showDeleteCaptain && (
        <YesNoDialogModal
          title="Are you sure you want to delete this captain?"
          onClose={() => setDeleteCaptain(false)}
          onSubmit={() => deleteCaptain()}
          submitting={deletingCaptain}
          error={deleteCaptainError}
        />
      )}
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(TeamsPage));
