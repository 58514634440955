import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formHeader: {
    fontSize: 25,
    marginBottom: 10
  }
});

function FormHeader({ classes, title }) {
  return <div className={classes.formHeader}>{title}</div>;
}

export default withStyles(styles)(FormHeader);
