import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withState } from "../../../services/state";
import {
  getClub as apiGetClub,
  updateClub as apiUpdateClub,
  getLogoUploadUrl as apiGetLogoUploadUrl,
  uploadLogo as apiUploadLogo,
  createGoCardlessMerchant as apiCreateGoCardlessMerchant,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
// import DialogModal from "../../components/dialogModal/DialogModal";
import Section from "../../components/section/Section";

import EditClubDetailsForm from "../../forms/editClubDetailsForm/EditClubDetailsForm";
import SetupGoCardlessForm from "../../forms/clubSetupForms/setupGoCardlessForm/SetupGoCardlessForm";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function ClubSettingsPage({ history, classes, context }) {
  // const [gettingClub, setGettingClub] = React.useState(false);
  const [club, setClub] = React.useState({});
  const [updatingClub, setUpdatingClub] = React.useState(false);
  const [updateClubError, setUpdateClubError] = React.useState("");
  const [goCardlessUrl, setGoCardlessUrl] = React.useState(null);

  const createGoCardlessMerchant = useCallback(async () => {
    const data = await apiCreateGoCardlessMerchant(context.currentClub.clubId);
    setGoCardlessUrl(data.url);
  }, [context.currentClub.clubId]);

  const getClub = useCallback(async () => {
    try {
      // setGettingClub(true);
      const response = await apiGetClub(context.currentClub.clubId);
      setClub({ ...response, logo: `https://slate-app-staging.s3.eu-west-2.amazonaws.com/clubs/${context.currentClub.clubId}/logo` });
      // setGettingClub(false);
      createGoCardlessMerchant();
    } catch (e) {
      // setGettingClub(false);
    }
  }, [context.currentClub.clubId, createGoCardlessMerchant]);

  const updateClub = async (payload) => {
    if (!payload.name || !payload.sport || !payload.address.addressLineOne || !payload.address.addressLineTwo || !payload.address.town || !payload.address.postcode) {
      return setUpdateClubError("Please complete all fields");
    }

    // if (!payload.logo) {
    //   return setUpdateClubError("Please select a logo");
    // }

    setUpdateClubError("");

    try {
      setUpdatingClub(true);
      await apiUpdateClub(context.currentClub.clubId, payload);

      if (payload.logo.startsWith("data:image")) {
        const getLogoUploadUrlResponse = await apiGetLogoUploadUrl();
        console.log("GET LOGO", getLogoUploadUrlResponse);
        await apiUploadLogo(getLogoUploadUrlResponse, payload.logoFile);
      }

      setUpdatingClub(false);
    } catch (e) {
      setUpdatingClub(false);
      setUpdateClubError(e.message);
    }
  };

  useEffect(() => {
    getClub();
  }, [getClub]);

  return (
    <PageWrapper history={history} showHeader={true} showSidebar={true} title="Club settings">
      {/* <DialogModal /> */}
      <div>
        <Section title="Club details">
          {club && club.clubId && (
            <EditClubDetailsForm
              submitButtonTitle="Save"
              initialValues={club}
              onSubmit={(clubDetailsPayload) => {
                updateClub(clubDetailsPayload);
              }}
              submitting={updatingClub}
              errorMessage={updateClubError}
            />
          )}
        </Section>
        {club && !club.isGoCardlessSetup && <Section title="Setup direct debit">{club && club.clubId && <SetupGoCardlessForm goCardlessUrl={goCardlessUrl} />}</Section>}
      </div>
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(ClubSettingsPage));
