import React, { useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { withState } from "../../../services/state";
import {
  getDetailedPaymentReport as apiGetDetailedPaymentReport,
  getCovidTrackAndTraceReport as apiGetCovidTrackAndTraceReport,
  getSummaryPaymentReport as apiGetSummaryPaymentReport,
  getEventTypes as apiGetEventTypes,
  getTeams as apiGetTeams,
} from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Section from "../../components/section/Section";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";

import DetailedPaymentReportTable from "../../tables/detailedPaymentReportTable/DetailedPaymentReportTable";
import SummaryPaymentReportTable from "../../tables/summaryPaymentReportTable/SummaryPaymentReportTable";
import CovidTrackAndTraceReportTable from "../../tables/covidTrackAndTraceReportTable/CovidTrackAndTraceReportTable";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
});

function ReportsPage({ history, context }) {
  // const [gettingClubReport, setGettingClubReport] = React.useState(false);
  const [detailedReportData, setDetailedReportData] = React.useState([]);
  const [filterReportStart, setFilterReportStart] = React.useState(
    moment().subtract(7, "days")
  );
  const [filterReportEnd, setFilterReportEnd] = React.useState(moment());
  const [summaryReportData, setSummaryReportData] = React.useState([]);
  const [
    covidTrackAndTraceReportData,
    setCovidTrackAndTraceReportData,
  ] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [filterTeam, setFilterTeam] = React.useState(null);
  const [filterEventType, setFilterEventType] = React.useState(null);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [reportType, setReportType] = React.useState("detailedPaymentReport");

  const getDetailedPaymentReport = useCallback(async () => {
    if (
      reportType !== "detailedPaymentReport" ||
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingClubReport(true);
      const data = await apiGetDetailedPaymentReport(
        context.currentClub.clubId,
        moment(filterReportStart).toISOString(),
        moment(filterReportEnd).toISOString(),
        {
          team: filterTeam,
          eventType: filterEventType,
          status: filterStatus,
        }
      );
      if (data && !data.reason) {
        setDetailedReportData(data);
      }
      // setGettingClubReport(false);
    } catch (e) {
      console.log(e);
      // setGettingClubReport(false);
    }
  }, [
    context.currentClub,
    context.currentPlayer,
    reportType,
    filterEventType,
    filterTeam,
    filterStatus,
    filterReportStart,
    filterReportEnd,
  ]);

  const getCovidTrackAndTraceReport = useCallback(async () => {
    if (
      reportType !== "covidTrackAndTraceReport" ||
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingClubReport(true);
      const data = await apiGetCovidTrackAndTraceReport(
        context.currentClub.clubId,
        moment(filterReportStart).toISOString(),
        moment(filterReportEnd).toISOString()
      );
      if (data && !data.reason) {
        setCovidTrackAndTraceReportData(data);
      }
      // setGettingClubReport(false);
    } catch (e) {
      console.log(e);
      // setGettingClubReport(false);
    }
  }, [
    context.currentClub,
    context.currentPlayer,
    reportType,
    filterReportStart,
    filterReportEnd,
  ]);

  const getSummaryPaymentReport = useCallback(async () => {
    if (
      reportType !== "summaryPaymentReport" ||
      !context.currentClub ||
      !context.currentClub.clubId ||
      !context.currentPlayer ||
      !context.currentPlayer.id
    ) {
      return false;
    }

    try {
      // setGettingClubReport(true);
      const data = await apiGetSummaryPaymentReport(
        context.currentClub.clubId,
        moment(filterReportStart).toISOString(),
        moment(filterReportEnd).toISOString(),
        {
          team: filterTeam,
          eventType: filterEventType,
          status: filterStatus,
        }
      );
      if (data && !data.reason) {
        setSummaryReportData(data);
      }
      // setGettingClubReport(false);
    } catch (e) {
      console.log(e);
      // setGettingClubReport(false);
    }
  }, [
    context.currentClub,
    context.currentPlayer,
    reportType,
    filterEventType,
    filterTeam,
    filterStatus,
    filterReportStart,
    filterReportEnd,
  ]);

  const getEventTypes = useCallback(async () => {
    if (!context.currentClub.clubId) {
      return false;
    }

    try {
      const data = await apiGetEventTypes(context.currentClub.clubId);
      if (data) {
        setEventTypes(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub.clubId]);

  const getTeams = useCallback(async () => {
    if (
      !context.currentPlayer ||
      !context.currentPlayer.id ||
      !context.currentClub.clubId
    ) {
      return false;
    }

    try {
      const data = await apiGetTeams(context.currentClub.clubId);
      if (data && !data.reason) {
        setTeams(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [context.currentClub, context.currentPlayer]);

  const renderContent = () => {
    if (reportType === "detailedPaymentReport") {
      return (
        <DetailedPaymentReportTable
          detailedReportData={detailedReportData}
          showDateRangeFilter={true}
          dateRangeFilterStart={filterReportStart}
          onDateRangeFilterStartChange={(value) => {
            setFilterReportStart(value);
          }}
          dateRangeFilterEnd={filterReportEnd}
          onDateRangeFilterEndChange={(value) => {
            setFilterReportEnd(value);
          }}
          filterSelects={[
            {
              items: teams.map((team) => {
                return {
                  label: team.name,
                  value: team.id,
                };
              }),
              label: "Filter team",
              value: filterTeam,
              onSelect: (value) => {
                setFilterTeam(value);
              },
              placeholder: "All teams",
            },
            {
              items:
                eventTypes &&
                Array.isArray(eventTypes) &&
                eventTypes.map((eventType) => {
                  return {
                    label: eventType.name,
                    value: eventType.id,
                  };
                }),
              label: "Filter event type",
              value: filterEventType,
              onSelect: (value) => {
                setFilterEventType(value);
              },
              placeholder: "All event types",
            },
            {
              items: [
                { value: "paid", label: "Paid" },
                { value: "owed", label: "Owed" },
              ],
              label: "Filter status",
              value: filterStatus,
              onSelect: (value) => {
                setFilterStatus(value);
              },
              placeholder: "Any status",
            },
          ]}
          showDownloadButton={true}
          downloadFilename="Slate Report"
        />
      );
    } else if (reportType === "summaryPaymentReport") {
      return (
        <SummaryPaymentReportTable
          summaryReportData={summaryReportData}
          showDateRangeFilter={true}
          dateRangeFilterStart={filterReportStart}
          onDateRangeFilterStartChange={(value) => {
            setFilterReportStart(value);
          }}
          dateRangeFilterEnd={filterReportEnd}
          onDateRangeFilterEndChange={(value) => {
            setFilterReportEnd(value);
          }}
          filterSelects={[
            {
              items: teams.map((team) => {
                return {
                  label: team.name,
                  value: team.id,
                };
              }),
              label: "Filter team",
              value: filterTeam,
              onSelect: (value) => {
                setFilterTeam(value);
              },
              placeholder: "All teams",
            },
            {
              items: eventTypes.map((eventType) => {
                return {
                  label: eventType.name,
                  value: eventType.id,
                };
              }),
              label: "Filter event type",
              value: filterEventType,
              onSelect: (value) => {
                setFilterEventType(value);
              },
              placeholder: "All event types",
            },
          ]}
          showDownloadButton={true}
          downloadFilename="Slate Report"
        />
      );
    } else if (reportType === "covidTrackAndTraceReport") {
      return (
        <CovidTrackAndTraceReportTable
          covidTrackAndTraceReportData={covidTrackAndTraceReportData}
          showDateRangeFilter={true}
          dateRangeFilterStart={filterReportStart}
          onDateRangeFilterStartChange={(value) => {
            setFilterReportStart(value);
          }}
          dateRangeFilterEnd={filterReportEnd}
          onDateRangeFilterEndChange={(value) => {
            setFilterReportEnd(value);
          }}
          showDownloadButton={true}
          downloadFilename="Covid Track and Trace Report"
        />
      );
    }
  };

  useEffect(() => {
    getEventTypes();
    getTeams();
  }, [getEventTypes, getTeams]);

  useEffect(() => {
    getDetailedPaymentReport();
    getSummaryPaymentReport();
    getCovidTrackAndTraceReport();
  }, [
    getDetailedPaymentReport,
    getSummaryPaymentReport,
    getCovidTrackAndTraceReport,
  ]);

  useEffect(() => {
    setFilterTeam(null);
    setFilterEventType(null);
    setFilterStatus(null);
    setFilterReportStart(moment().subtract(7, "days"));
    setFilterReportEnd(moment());
  }, [reportType]);

  return (
    <PageWrapper
      history={history}
      showHeader={true}
      showSidebar={true}
      title="Report"
    >
      <Section>
        <DropdownSelect
          label={"Report type"}
          value={reportType}
          items={[
            {
              label: "Detailed payment report",
              value: "detailedPaymentReport",
            },
            { label: "Summary payment report", value: "summaryPaymentReport" },
            {
              label: "Covid track and trace report",
              value: "covidTrackAndTraceReport",
            },
          ]}
          onSelect={(value) => setReportType(value)}
        />
        {renderContent()}
      </Section>
    </PageWrapper>
  );
}

export default withStyles(styles)(withState(ReportsPage));
