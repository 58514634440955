import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Label from "../label/Label";

const styles = (theme) => ({
  singleLineTextInput: {
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    border: "1px solid #D7D7D7",
    borderRadius: "3px",
    outline: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "18px",
  },
  invalidInput: {
    border: "1px solid red",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
  },
  startAdornment: {
    marginRight: 5,
    fontSize: 23,
  },
});

function SingleLineTextInput({ classes, style, value, label, type, showInvalidInput, onChange, placeholder, startAdornment }) {
  let inputClassName = classes.input;

  if (showInvalidInput) {
    inputClassName += ` ${classes.invalidInput}`;
  }

  return (
    <div className={classes.singleLineTextInput} style={style || {}}>
      {label && <Label title={label} />}
      <div className={classes.inputWrapper}>
        {startAdornment && <div className={classes.startAdornment}>{startAdornment}</div>}
        <input className={inputClassName} value={value} type={type || "text"} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} />
      </div>
    </div>
  );
}

export default withStyles(styles)(SingleLineTextInput);
