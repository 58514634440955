import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "../../components/button/Button";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const playerInformationOptions = [
  {
    label: "Not requested",
    value: 0,
  },
  {
    label: "Requested",
    value: 1,
  },
  {
    label: "Required",
    value: 2,
  },
];

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  formActions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
});

function PlayerInformationForm({ classes, initialValues, onSubmit, errorMessage, submitting }) {
  // const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState(initialValues || {});

  return (
    <div className={classes.section}>
      <DropdownSelect label="HOME ADDRESS" items={playerInformationOptions} value={payload.homeAddress} onSelect={(value) => storePayload({ ...payload, homeAddress: value })} />
      <DropdownSelect label="DATE OF BIRTH" items={playerInformationOptions} value={payload.dateOfBirth} onSelect={(value) => storePayload({ ...payload, dateOfBirth: value })} />
      <DropdownSelect label="MEDICAL CONDITIONS" items={playerInformationOptions} value={payload.medicalConditions} onSelect={(value) => storePayload({ ...payload, medicalConditions: value })} />
      <DropdownSelect label="PHONE NUMBER" items={playerInformationOptions} value={payload.phoneNumber} onSelect={(value) => storePayload({ ...payload, phoneNumber: value })} />
      <DropdownSelect
        label="EMERGENCY CONTACT NAME"
        items={playerInformationOptions}
        value={payload.emergencyContactName}
        onSelect={(value) => storePayload({ ...payload, emergencyContactName: value })}
      />
      <DropdownSelect
        label="EMERGENCY CONTACT NUMBER"
        items={playerInformationOptions}
        value={payload.emergencyContactNumber}
        onSelect={(value) => storePayload({ ...payload, emergencyContactNumber: value })}
      />
      <ErrorMessage message={errorMessage} />
      <div className={classes.formActions}>
        <Button
          title="Save"
          onClick={() => {
            // setSubmitAttempted(true);
            onSubmit(payload);
          }}
          submitting={submitting}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(PlayerInformationForm);
