import React from "react";

import { withState } from "../../../services/state";
import { login as apiLogin } from "../../../services/api";

import PageWrapper from "../../components/pageWrapper/PageWrapper";
import AuthenticationFlow from "../../components/authenticationFlow/AuthenticationFlow";

import LoginForm from "../../forms/loginForm/LoginForm";

const login = async ({ history, dispatch, payload, setLoggingIn, setLoginError }) => {
  if (!payload || !payload.emailAddress || !payload.password) {
    return setLoginError("Please complete all fields");
  }

  setLoginError("");

  try {
    setLoggingIn(true);
    const data = await apiLogin(payload);

    if (data.success) {
      dispatch({
        type: "siteLoaded",
        tokens: {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        },
        loggedIn: true,
        loaded: false,
      });
      history.push("/");
    } else if (data.errors) {
      setLoginError(data.errors[0].reason);
    } else {
      setLoginError("An error occurred trying to login");
    }
    setLoggingIn(false);
  } catch (e) {
    setLoggingIn(false);
    const errorMessage =
      e.message && e.message.errors && Object.keys(e.message.errors).length > 0 && e.message.errors[Object.keys(e.message.errors)] && e.message.errors[Object.keys(e.message.errors)][0];
    setLoginError(errorMessage);
  }
};

const LoginPage = ({ history, dispatch }) => {
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [loginError, setLoginError] = React.useState("");

  return (
    <PageWrapper history={history} showHeader={true}>
      <AuthenticationFlow
        currentStep={0}
        steps={[<LoginForm onSubmit={(payload) => login({ history, dispatch, payload, setLoggingIn, setLoginError })} errorMessage={loginError} submitting={loggingIn} />]}
      />
    </PageWrapper>
  );
};

export default withState(LoginPage);
