import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

const headings = [
  {
    label: "NAME",
    key: "name",
  },
];

function PlayerRegistrationStatusTable({
  classes,
  teams,
  onAddButtonClick,
  onEditTeam,
  onDeleteTeam,
  onRowClick,
  hideControls,
  hideSearch,
  hideAddButton,
}) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        headings={headings}
        data={teams}
        hideControls={hideControls}
        addButtonText={!hideAddButton && "Add team"}
        onAddButtonClick={(payload) => onAddButtonClick(payload)}
        onRowClick={(id) => (onRowClick ? onRowClick(id) : null)}
        rowActions={[
          { type: "edit", onClick: (team) => onEditTeam(team) },
          { type: "delete", onClick: (team) => onDeleteTeam(team) },
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(PlayerRegistrationStatusTable);
