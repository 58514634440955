import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
});

const headings = [
  {
    label: "First name",
    key: "firstName",
  },
  {
    label: "Last name",
    key: "lastName",
  },
  {
    label: "Team",
    key: "team",
  },
  {
    label: "Event",
    key: "event",
  },
  {
    label: "Event date",
    key: "eventDate",
    type: "date",
  },
  {
    label: "Event time",
    key: "eventTime",
    startKey: "eventStartTime",
    endKey: "eventEndTime",
    type: "timeRange",
  },
  {
    label: "Event location",
    key: "eventLocation",
  },
];

function CovidTrackAndTraceReportTable({
  classes,
  covidTrackAndTraceReportData,
  addButtonText,
  onAddButtonClick,
  hideSearch,
  onEditFeeCategory,
  onDeleteFeeCategory,
  additionalButtons,
  showDateRangeFilter,
  dateRangeFilterStart,
  onDateRangeFilterStartChange,
  dateRangeFilterEnd,
  onDateRangeFilterEndChange,
  filterSelects,
  showDownloadButton,
  downloadFilename,
}) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={covidTrackAndTraceReportData}
        onAddButtonClick={
          onAddButtonClick ? (payload) => onAddButtonClick(payload) : null
        }
        rowActions={[
          {
            type: "edit",
            onClick: onEditFeeCategory
              ? (member) => onEditFeeCategory(member)
              : null,
          },
          {
            type: "delete",
            onClick: onDeleteFeeCategory
              ? (member) => onDeleteFeeCategory(member)
              : null,
          },
        ]}
        additionalButtons={additionalButtons}
        showDateRangeFilter={showDateRangeFilter}
        dateRangeFilterStart={dateRangeFilterStart}
        onDateRangeFilterStartChange={(value) =>
          onDateRangeFilterStartChange(value)
        }
        dateRangeFilterEnd={dateRangeFilterEnd}
        onDateRangeFilterEndChange={(value) =>
          onDateRangeFilterEndChange(value)
        }
        filterSelects={filterSelects}
        showDownloadButton={showDownloadButton}
        downloadFilename={downloadFilename}
      />
    </div>
  );
}

export default withStyles(styles)(CovidTrackAndTraceReportTable);
