import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "transparent",
  },
});

const headings = [
  {
    label: "FIRST NAME",
    key: "player",
    subKey: "firstName",
  },
  {
    label: "LAST NAME",
    key: "player",
    subKey: "lastName",
  },
];

function CaptainsTable({ classes, captains, addButtonText, onAddButtonClick, hideSearch, onDeleteCaptain }) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={captains}
        onAddButtonClick={(payload) => onAddButtonClick(payload)}
        rowActions={[{ type: "delete", onClick: (teamCaptain) => onDeleteCaptain(teamCaptain) }]}
      />
    </div>
  );
}

export default withStyles(styles)(CaptainsTable);
