import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Label from "../label/Label";

const styles = (theme) => ({
  radioButtonSelector: {
    marginTop: 30,
    marginBottom: 30,
  },
  items: {},
  item: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    marginBottom: 10,
  },
  itemSelect: {
    height: 30,
    width: 30,
    borderRadius: 15,
    border: "2px solid #3cc77b",
    marginRight: 5,
    cursor: "pointer",
  },
  selectedItemSelect: {
    border: "10px solid #3cc77b",
  },
});

function RadioButtonSelector({
  classes,
  label,
  selectedItem,
  items,
  onSelect,
}) {
  const renderRadioButtons = () => {
    return items.map((item) => {
      return (
        <RadioButton
          classes={classes}
          label={item.label}
          selected={selectedItem && item.value === selectedItem}
          onClick={() => onSelect(item.value)}
        />
      );
    });
  };

  return (
    <div className={classes.radioButtonSelector}>
      {label && <Label title={label} />}
      <div className={classes.items}>{renderRadioButtons()}</div>
    </div>
  );
}

function RadioButton({ classes, label, selected, onClick }) {
  let itemSelectClassName = classes.itemSelect;

  if (selected) {
    itemSelectClassName += ` ${classes.selectedItemSelect}`;
  }

  return (
    <div onClick={() => onClick()} className={classes.item}>
      <div className={itemSelectClassName}></div>
      <div className={classes.itemLabel}>{label}</div>
    </div>
  );
}

export default withStyles(styles)(RadioButtonSelector);
