import React, { useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";
import FuzzySearch from "fuzzy-search";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import Label from "../label/Label";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";
import DropdownSelect from "../../components/dropdownSelect/DropdownSelect";

const styles = (theme) => ({
  tableHeader: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableWrapper: {
    width: "100%",
    overflow: "scroll",
  },
  table: {
    width: "100%",
    borderSpacing: "0px",
  },
  tableHeading: {
    color: "#7B7B7B",
    borderBottom: "1px solid #D7D7D7",
  },
  tableCell: {
    borderBottom: "1px solid #D7D7D7",
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 13,
  },
  tableActionsCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginLeft: 5,
    },
  },
  yesValue: {
    backgroundColor: "#00823E",
    width: 100,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  noValue: {
    backgroundColor: "lightgrey",
    width: 100,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterSection: {
    marginBottom: 20,
    display: "flex",
  },
  filterSelects: {
    display: "flex",
  },
  filterSelect: {
    width: 150,
    marginRight: 10,
  },
  cellTag: {
    marginLeft: 5,
    background: "#00823e",
    padding: 5,
    color: "#fff",
  },
});

function renderTableHeadings({ classes, headings }) {
  if (headings) {
    return headings.map((heading) => {
      if (typeof heading.label === "string") {
        return (
          <TableCell
            key={"heading-" + heading.key}
            className={classes.tableHeading}
          >
            <Label title={heading.label} />
          </TableCell>
        );
      }
      return false;
    });
  }
  return false;
}

function getIcon(type) {
  switch (type) {
    case "edit": {
      return <EditIcon />;
    }

    case "delete": {
      return <DeleteIcon />;
    }

    case "email": {
      return <EmailIcon />;
    }

    default: {
      return <EditIcon />;
    }
  }
}

function renderTableActions({ rowIndex, dataItem, rowActions }) {
  return (
    rowActions &&
    rowActions
      .filter(
        (rowAction) =>
          rowAction.onClick &&
          (!rowAction.hidden || !rowAction.hidden(dataItem.data))
      )
      .map((rowAction) => {
        return (
          <div key={`action-${rowIndex}-${rowAction.type}`}>
            <IconButton
              aria-label="delete"
              onClick={() => rowAction.onClick(dataItem.data)}
            >
              {getIcon(rowAction.type)}
            </IconButton>
          </div>
        );
      })
  );
}

function filterData({ searchTerm, data, headings }) {
  let keys = [];
  for (let i = 0; i < headings.length; i++) {
    const heading = headings[i];

    if (heading.key && !heading.subKey) {
      keys.push(heading.key);
    } else if (heading.key && heading.subKey) {
      keys.push(`${heading.key}.${heading.subKey}`);
    }
  }

  const searcher = new FuzzySearch(data, keys, {
    caseSensitive: false,
  });
  const result = searcher.search(searchTerm);

  return result;
}

function renderAdditionalButtons({ additionalButtons }) {
  return additionalButtons.map((additionalButton) => {
    return (
      <div>
        <Button
          title={additionalButton.title}
          onClick={(payload) => additionalButton.onPress(payload)}
        />
      </div>
    );
  });
}

function transformData({ data, headings }) {
  return data.map((dataItem) => {
    let transformedDataItem = {
      data: dataItem,
    };

    headings.forEach((heading, i) => {
      let value = "";

      if (heading.type === "timeRange") {
        value = `${dataItem[heading.startKey]} - ${dataItem[heading.endKey]}`;
        return (transformedDataItem[heading.key] = value);
      }

      let dataValue = dataItem[heading.key];
      if (typeof dataValue === "object" && dataValue) {
        if (heading.subKey) {
          dataValue = dataValue[heading.subKey];
        } else {
          dataValue = JSON.stringify(dataValue);
        }
      }

      if (typeof heading.key === "string") {
        if (
          heading.type === "currency" &&
          dataValue !== null &&
          dataValue !== undefined
        ) {
          value = `£${dataValue.toFixed(2)}`;
        } else if (heading.type === "yesNo") {
          if (dataValue) {
            value = "Yes";
          } else {
            value = "No";
          }
        } else if (heading.type === "date" && dataValue) {
          if (dataValue.toString() === "0001-01-01T00:00:00") {
            return null;
          }
          value = moment(dataValue).format("DD/MM/YYYY");
        } else {
          value = dataValue;
        }
      } else {
        value = dataValue;
      }

      if (heading.subKey) {
        return (transformedDataItem[heading.key] = {
          ...transformedDataItem[heading.key],
          [heading.subKey]: value,
        });
      }
      return (transformedDataItem[heading.key] = value);
    });

    return transformedDataItem;
  });
}

function renderTableRows({
  classes,
  headings,
  data,
  hideControls,
  rowActions,
  searchTerm,
  onRowClick,
}) {
  if (data) {
    let transformedData = transformData({ data, headings });
    return filterData({ searchTerm, data: transformedData, headings }).map(
      (dataItem, i) => {
        return (
          <TableRow key={"row-" + i}>
            {headings.map((heading, i) => {
              let value = null;

              if (typeof heading.key === "string") {
                if (heading.type === "yesNo") {
                  if (dataItem[heading.key] === "Yes") {
                    return (
                      <TableCell
                        onClick={() =>
                          onRowClick && onRowClick(dataItem.data.id)
                        }
                        key={"cell-" + i}
                        className={classes.tableCell}
                      >
                        <span className={classes.yesValue}>
                          {dataItem[heading.key]}
                        </span>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        onClick={() =>
                          onRowClick && onRowClick(dataItem.data.id)
                        }
                        key={"cell-" + i}
                        className={classes.tableCell}
                      >
                        <span className={classes.noValue}>
                          {dataItem[heading.key]}
                        </span>
                      </TableCell>
                    );
                  }
                } else {
                  value = dataItem[heading.key];
                }
              } else if (heading.key.constructor === Array) {
                value = dataItem[heading.key[0]];
                for (let i = 1; i < heading.key.length; i++) {
                  if (value) {
                    value = value[heading.key[i]];
                  } else {
                    return <td key={"cell-" + i} />;
                  }
                }
              }

              if (heading.subKey) {
                value = dataItem[heading.key][heading.subKey];
              }

              if (!value) {
                return (
                  <TableCell
                    onClick={() => onRowClick && onRowClick(dataItem.data.id)}
                    key={"cell-" + i}
                    className={classes.tableCell}
                  ></TableCell>
                );
              }

              return (
                <TableCell
                  onClick={() => onRowClick && onRowClick(dataItem.data.id)}
                  key={"cell-" + i}
                  className={classes.tableCell}
                >
                  {value.toString()}{" "}
                  {heading.tag && heading.tag(dataItem.data) && (
                    <span className={classes.cellTag}>
                      {heading.tag(dataItem.data)}
                    </span>
                  )}
                </TableCell>
              );
            })}

            {!hideControls && (
              <TableCell className={classes.tableCell} align="right">
                <div className={classes.tableActionsCell}>
                  {renderTableActions({ rowIndex: i, dataItem, rowActions })}
                </div>
              </TableCell>
            )}
          </TableRow>
        );
      }
    );
  }
  return false;
}

function DataTable({
  classes,
  headings,
  data,
  onRowClick,
  addButtonText,
  onAddButtonClick,
  rowLink,
  hideControls,
  hideSearch,
  rowActions,
  additionalButtons,
  showDateRangeFilter,
  dateRangeFilterStart,
  onDateRangeFilterStartChange,
  dateRangeFilterEnd,
  onDateRangeFilterEndChange,
  filterSelects,
  showDownloadButton,
  downloadFilename,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [csvData, setCSVData] = React.useState([]);

  const generateCSV = useCallback(() => {
    let buildCSVData = [];

    buildCSVData.push(headings.map((heading) => heading.label));

    let transformedData = transformData({ data, headings });
    transformedData.forEach((dataItem) => {
      buildCSVData.push(
        headings.map((heading) => {
          if (heading.subKey) {
            return dataItem[heading.key][heading.subKey];
          }
          return dataItem[heading.key];
        })
      );
    });

    setCSVData(buildCSVData);
  }, [data, headings]);

  useEffect(() => {
    if (showDownloadButton) {
      generateCSV();
    }
  }, [data, generateCSV, showDownloadButton]);

  return (
    <div>
      <div className={classes.tableHeader}>
        <div>
          {!hideSearch && !hideControls && (
            <SingleLineTextInput
              placeholder="Search..."
              style={{ marginRight: 0, marginBottom: 0 }}
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
            />
          )}
          {/* <IconButton /> */}
        </div>
        <div className={classes.buttons}>
          <div>
            {addButtonText && !hideControls && onAddButtonClick && (
              <Button
                title={addButtonText}
                onClick={(payload) => onAddButtonClick(payload)}
              />
            )}
          </div>
          {showDownloadButton && (
            <div style={{ marginLeft: 5 }}>
              <CSVLink filename={`${downloadFilename}.csv`} data={csvData}>
                <Button title="Download report" />
              </CSVLink>
            </div>
          )}
          {additionalButtons && (
            <div>{renderAdditionalButtons({ additionalButtons })}</div>
          )}
        </div>
      </div>
      {(showDateRangeFilter || (filterSelects && filterSelects.length > 0)) && (
        <div className={classes.filterSection}>
          {showDateRangeFilter && (
            <div>
              <Label title={"Start date"} />
              <DatePicker
                value={dateRangeFilterStart}
                onChange={(value) => onDateRangeFilterStartChange(value)}
                variant="inline"
                disableToolbar={true}
                inputVariant="outlined"
                style={{ marginRight: 10 }}
              />
            </div>
          )}
          {showDateRangeFilter && (
            <div>
              <Label title={"End date"} />
              <DatePicker
                value={dateRangeFilterEnd}
                onChange={(value) => onDateRangeFilterEndChange(value)}
                variant="inline"
                disableToolbar={true}
                inputVariant="outlined"
                style={{ marginRight: 10 }}
              />
            </div>
          )}
          {filterSelects &&
            filterSelects.map((filterSelect) => (
              <div
                key={`filter-${filterSelect.label}`}
                className={classes.filterSelect}
              >
                <DropdownSelect
                  label={filterSelect.label}
                  value={filterSelect.value}
                  items={filterSelect.items}
                  onSelect={(value) => filterSelect.onSelect(value)}
                  placeholder={filterSelect.placeholder}
                />
              </div>
            ))}
        </div>
      )}
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {renderTableHeadings({ classes, headings })}
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableRows({
              classes,
              headings,
              data,
              rowLink,
              hideControls,
              rowActions,
              searchTerm,
              onRowClick,
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default withStyles(styles)(DataTable);
