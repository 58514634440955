import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  membersList: {
    marginBottom: 20,
  },
  selectPlayer: {
    padding: "10px",
    cursor: "pointer",
    "&:not(:last-child)": {
      borderBottom: "1px solid #ececec",
    },
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
});

function AddCaptainForm({ classes, members, onAddCaptain, onClose }) {
  const renderContent = () => {
    return (
      <div>
        <div className={classes.membersList}>
          {members.map((member) => (
            <div className={classes.selectPlayer} onClick={() => onAddCaptain(member)}>
              <div>
                {member.firstName} {member.lastName}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Select a member" />
      </div>
      <div>{renderContent()}</div>
    </div>
  );
}

export default withStyles(styles)(AddCaptainForm);
