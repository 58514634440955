import React from "react";
import { withStyles } from "@material-ui/core/styles";

import FormHeader from "../../components/formHeader/FormHeader";
import SingleLineTextInput from "../../components/singleLineTextInput/SingleLineTextInput";
import Button from "../../components/button/Button";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";

const styles = (theme) => ({
  section: {
    width: "100%",
    backgroundColor: "#fff",
  },
  authLinks: {
    marginTop: 20,
  },
  authLink: {
    margin: "10px 0",
  },
});

const isInvalidEmailAddress = (emailAddress) => !emailAddress;

function AdminSelectUserForm({ classes, onSubmit, errorMessage, submitting }) {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [payload, storePayload] = React.useState({});

  return (
    <div className={classes.section}>
      <div className={classes.setupInformation}>
        <FormHeader title="Login as user" />
      </div>
      <SingleLineTextInput
        label="USER EMAIL ADDRESS"
        onChange={(value) => storePayload({ ...payload, emailAddress: value })}
        showInvalidInput={
          submitAttempted && isInvalidEmailAddress(payload.emailAddress)
        }
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Button
        title="Login"
        onClick={() => {
          setSubmitAttempted(true);
          onSubmit(payload);
        }}
        submitting={submitting}
      />
    </div>
  );
}

export default withStyles(styles)(AdminSelectUserForm);
