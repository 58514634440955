import React from "react";

import Equalizer from "@material-ui/icons/Equalizer";
import EventNote from "@material-ui/icons/EventNote";
import Security from "@material-ui/icons/Security";
import People from "@material-ui/icons/People";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import Settings from "@material-ui/icons/Settings";
import Info from "@material-ui/icons/Info";

const NavbarConfig = [
  {
    title: "Report",
    route: "/reports",
    icon: <Equalizer />,
  },
  {
    title: "Teams",
    route: "/teams",
    icon: <Security />,
  },
  {
    title: "Members",
    route: "/members",
    icon: <People />,
  },
  // {
  //   title: "Events",
  //   route: "/events",
  //   icon: <Event />
  // },
  {
    title: "Event types",
    route: "/event-types",
    icon: <EventNote />,
  },
  {
    title: "Fee levels",
    route: "/fee-levels",
    icon: <MonetizationOn />,
  },
  {
    title: "Event fees",
    route: "/event-fees",
    icon: <AccountBalanceWallet />,
  },
  {
    title: "Club settings",
    route: "/club-settings",
    icon: <Settings />,
  },
  {
    title: "Player information",
    route: "/player-information",
    icon: <Info />,
  },
];

export default NavbarConfig;
