import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Table from "../../components/table/Table";

const styles = theme => ({
  section: {
    width: "100%",
    backgroundColor: "#fff"
  }
});

const headings = [
  {
    label: "NAME",
    key: "name"
  }
];

function FeeCategoriesTable({ classes, feeCategories, addButtonText, onAddButtonClick, hideSearch, onEditFeeCategory, onDeleteFeeCategory }) {
  return (
    <div className={classes.section}>
      <Table
        hideSearch={hideSearch}
        addButtonText={addButtonText}
        headings={headings}
        data={feeCategories}
        onAddButtonClick={onAddButtonClick ? payload => onAddButtonClick(payload) : null}
        rowActions={[
          { type: "edit", onClick: onEditFeeCategory ? member => onEditFeeCategory(member) : null },
          { type: "delete", onClick: onDeleteFeeCategory ? member => onDeleteFeeCategory(member) : null }
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(FeeCategoriesTable);
